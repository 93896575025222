<!-- HEIGHT - bei calculatedTableHeight wird der berechnete Wert eingesetzt -->
<div [class]="tableCss" [style.height]="calculatedTableHeight">

  <div class="vsTable" style="height: 100%; overflow-x: auto">


    <!-- <div class="vsTable" style="height: calc(100vh - 515px)"> -->

    <!--
      Von 100% Höhe des View Ports müssen abgezogen werden:
        # App Header
        # ggf. Abstand zwischen App Header und Tab Container
        # Tab Container
        # View Detailbereich
        style="height: calc(100vh - 515px)"
                                     App Header inkl. Tab Container     Jeder Detailbereich
    -->

    <!------------------------------------------------------------------------------------------------
    --  #### Variante 1 ####
    ------------------------------------------------------------------------------------------------->

    <!-- <p-table [value]="Data"> -->

    <!-- Header ----------------------------------------------------------------------------------->

    <!-- <ng-template pTemplate="header">
          <tr>
              <th *ngFor = "let col of ColumnDefs">{{col.ColumnCaption}}</th>
          </tr>
      </ng-template> -->


    <!-- Body ----------------------------------------------------------------------------------->

    <!-- <ng-template pTemplate="body" let-record>
        <tr>
            <td *ngFor = "let col of ColumnDefs">{{record[col.FieldName]}}</td>
        </tr>
      </ng-template> -->

    <!-- </p-table> -->


    <!------------------------------------------------------------------------------------------------
    --  #### Variante 2 ####
    --
    --  Wird benötigt für:
    --  # reorderable colums
    --  # csv export
    --  # global filtering without globalFilterFields
    ------------------------------------------------------------------------------------------------->

    <div>
      <ng-template [ngTemplateOutlet]="templateTitle"></ng-template>
    </div>

    <!-- // $Rev MP 2021-06-23: style width-Wert auf 100% gesetzt, dafür hat eines der umschließenden Elemente overflow-x: hidden -->
    <!-- // $Rev MP 2021-06-17: scrollHeight = flex für dynamische Höhe -->
    <!-- <p-table  #tableMainInternal  styleClass="p-datatable-striped" -->

    <!-- sortMode          = multiple
      [multiSortMeta]        = [SortDefs] -->
    <!-- <pre>{{ this.DataSelected | json}}</pre> -->


    <p-table #tableMainInternal
      [columns]="DOMain.Dataset.FieldDefs"
      [value]="DOMain.Dataset.Data"
      [paginator]="false"
      [rowHover]="true"
      [dataKey]="dataKey"
      (onRowReorder)="doOnDrop($event)"
      [selectionMode]="selectionMode"
      [(selection)]="dataSelected" sort-mode="single"
      (sortFunction)="customSort($event)" [customSort]="true"
      [scrollable]="true"
      scrollHeight="flex"
      [style]="{width: '100%'}"
      (onRowSelect)="doOnRowSelect($event)"
      (onRowUnselect)="doOnRowUnselect($event)"
      [contextMenu]="contextmenu"
      [contextMenuSelectionMode]=""
      (onContextMenuSelect)="doContextMenuSelect($event)"
      [reorderableColumns]="reorderableColumns"
      [resizableColumns]="resizableColumns">
      <!-- <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <button type="button" pButton pRipple icon="pi pi-file-o" (click)="tableMainInternal.exportCSV()" csvSeparator=";" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
          </div>
      </ng-template> -->


      <!-- // $Rev MP 2021-06-01: Steuerung der Spaltenbreite. verkleinert sich sonst zu weit, wenn kein fester Wert festgelegt wird. Evtl. kann man hier min-width verwenden. -->
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup [ngClass]="selectionMode !== 'multiple' ? 'single-select' : ''">
          <!-- <col style="width:10px;"> -->
          <col *ngFor="let col of columns" [style.width]="col.Size + 'px'" />

        </colgroup>

      </ng-template>


      <!-- Header --------------------------------------------------------------------------------->

      <ng-template pTemplate="header" let-columns>
        <tr>
          <!-- <th *ngFor = "let col of columns" [pSortableColumn]="col.FieldName" [pSortableColumnDisabled]="!col.ColumnIsSortable">
            {{col.ColumnCaption}}
            <p-sortIcon *ngIf = "col.ColumnIsSortable" [field]="col.FieldName"></p-sortIcon>
          </th> -->
          <!-- <th *ngFor = "let col of columns" [pSortableColumn]="col.Name" [pSortableColumnDisabled]="!col.ColumnIsSortable">
            {{col.Caption}}
            <p-sortIcon *ngIf = "col.ColumnIsSortable" [field]="col.Name"></p-sortIcon>
          </th> -->
          <!-- *ngIf="checkBoxesEnabled || selectionMode==='single'" -->
          <th *ngIf="isExpanded" style="width: 3rem"></th>
          <th style="width:3rem" *ngIf="rowIsReorderable"></th>
          <th *ngIf="checkBoxesEnabled">
            <p-tableHeaderCheckbox [style.display]="checkBoxesEnabled ? 'inherit' : 'none'"></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" [pReorderableColumn]="reorderable" [pResizableColumn]="resizable"
            [pSortableColumn]="col.Name" [pSortableColumnDisabled]="false">
            {{col.Caption | translate}}
            <div *ngIf="col.Sort === true">
              <p-sortIcon [field]="col.name"></p-sortIcon>
            </div>
          </th>

          <!-- Wichtig, nicht auskommentieren oder ändern. Eintrag steuert die Breiten der Tabellenheader. -->
          <th *ngIf="!checkBoxesEnabled">
            <p-tableHeaderCheckbox style="display:none"></p-tableHeaderCheckbox>
        </th>
          <!-- Beispiel aus PrimeNG-Doku -->
          <!-- <th *ngFor="let col of columns" [pSortableColumn]="col.name">
            {{col.caption}}
            <p-sortIcon [field]="col.name"></p-sortIcon>
        </th> -->


        </tr>
      </ng-template>


      <!-- Body ----------------------------------------------------------------------------------->

      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex" let-record
        let-expanded="expanded">
        <tr *ngIf="DOMain.Dataset.Data.length !== 0" [pReorderableRow]="index" [pSelectableRow]="record"
          [pContextMenuRow]="record" (click)="doOnRowClick(record)">
          <!-- <td *ngFor = "let col of columns">{{record[col.Name]}}</td> -->
          <td *ngIf="isExpanded">
            <button type="button" pButton pRipple [pRowToggler]="record"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td *ngIf="rowIsReorderable">
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>
          <td *ngIf="checkBoxesEnabled">
            <p-tableCheckbox [style.display]="checkBoxesEnabled ? 'inherit' : 'none'" [pSelectableRow]="record"
              [value]="record"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns">

            <div *ngIf="(col.Type != 'ftBoolean' && col.Type != 'ftSystem' && col.Type != 'ftProgress' &&
                        col.Type != 'ftStatus' && col.Type != 'ftVerify' && col.Type != 'ftBadge' &&
                        col.Type != 'ftInOut'  && col.Type != 'ftInOutEP' && col.Type != 'ftReadUnread' &&
                        col.Type != 'ftEditButton' && col.Type != 'ftDeleteButton' && col.Type != 'ftReadUnreadBm' &&
                        col.Type != 'ftVisibility')">
              <div *ngIf="col.Alignment === 'alNone'">
                <div>
                  {{doRetrieveValue(col.Type, record[col.Name])}}
                  <!-- {{record[col.Name]}} -->
                </div>
              </div>

              <div *ngIf="col.Alignment === 'alRight'" style="text-align: right;">
                <div>
                  {{doRetrieveValue(col.Type, record[col.Name])}}
                  <!-- {{record[col.Name]}} -->
                </div>
              </div>

              <div *ngIf="col.Alignment === 'alLeft'" style="text-align: left;">
                <!-- <div *ngIf="col.Name === 'OpenInEdit'">
                  <button (click)="doActionTicketOpen(record)">Ticket öffnen</button>
                </div>
                <div>
                  {{doRetrieveValue(col.Type, record[col.Name])}}
                  {{record[col.Name]}} -->
                <!-- </div> -->

                <!-- <div *ngIf="col.Type === 'ftCustomButton'" class="{{col.CssClass}}">
                  <img alt="Open" src={{col.ImgSrc}} (click)="doCustomActionButtonClick(record)"/>
                </div>-->

                <!-- Image mit Action  -->
                <div *ngIf="col.ImgSrc && col.Type !== 'ftBadge'" class="{{col.CssClass}}">
                  <img alt="Open" src={{col.ImgSrc}} (click)="doCustomActionButtonClick(record, col.Name)" />
                </div>

                <div>
                  {{doRetrieveValue(col.Type, record[col.Name])}}
                  <!-- {{record[col.Name]}} -->
                </div>
              </div>

              <div *ngIf="col.Alignment === 'alCenter'" style="text-align: center;">
                <div>
                  {{doRetrieveValue(col.Type, record[col.Name])}}
                  <!-- {{record[col.Name]}} -->
                </div>
              </div>
            </div>

            <!-- Image mit Action + Badge mit Value -->
            <div *ngIf="col.ImgSrc && col.Type === 'ftBadge'" class="{{col.CssClass}}">
              <img alt="Open" src={{col.ImgSrc}} (click)="doCustomActionButtonClick(record, col.Name)" />
              <div *ngIf="record[col.Name] > 0" class="vsAntragsTableBadge"><p-badge [value]="record[col.Name]"
                  severity="danger" (click)="doCustomActionButtonClick(record)"></p-badge></div>
            </div>

            <div *ngIf="col.Type === 'ftEditButton'" class="{{col.CssClass}}">
              <img alt="Open" src={{col.ImgSrc}} (click)="doActionEditClick(record)" />
            </div>

            <!-- <div *ngIf="col.Type === 'ftDeleteButton'" class="{{col.CssClass}}">
              <img alt="Open" src={{col.ImgSrc}} (click)="doActionDeleteClick(record)"/>
            </div> -->


            <div *ngIf="col.Type === 'ftBoolean'">
              <div *ngIf="record[col.Name]" style="text-align: center;">

                <img alt="" src="/assets/img/Icons/vs-check-green-bold.svg" width="18px" height="18px" />
                <!-- <img alt="logo" src="/assets/img/Icons/check_OK.bmp"/> -->
              </div>
            </div>

            <div *ngIf="col.Type === 'ftSystem'">
              <div *ngIf="record[col.Name]" style="text-align: center;">

                <img alt="" src="/assets/img/Icons/vs-lock-outline.svg" width="18px" height="18px" />

              </div>
            </div>

            <div *ngIf="col.Type === 'ftInOut'">
              <div *ngIf="record[col.Name]===true" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/arrow-left-bold-yellow.svg" width="18px" height="18px" />
              </div>
              <div *ngIf="record[col.Name]===false" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/arrow-right-bold-blue.svg" width="18px" height="18px" />
              </div>
            </div>
            <!-- Anzeige ein-/ausgehende Nachrichten im Elternportal - ein-/ausgehend vertauscht  -->
            <div *ngIf="col.Type === 'ftInOutEP'">
              <div *ngIf="record[col.Name]===true" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/arrow-right-bold-blue.svg" width="18px" height="18px" />
              </div>
              <div *ngIf="record[col.Name]===false" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/arrow-left-bold-yellow.svg" width="18px" height="18px" />
              </div>
            </div>


            <div *ngIf="col.Type === 'ftReadUnread'">
              <div *ngIf="record[col.Name]===true" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/email-open-outline-green.svg" width="18px" height="18px" />
              </div>
              <div *ngIf="record[col.Name]===false" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/email-outline-red.svg" width="18px" height="18px" />
              </div>
            </div>
            <!-- $REV MP 2023-06-12: Kein Symbol in bm bei ausgehenden Nachrichten -->
            <div *ngIf="col.Type === 'ftReadUnreadBm'">
              <div *ngIf="record[col.Name[0]] && !record[col.Name[1]]" style="text-align: center;">
              </div>
              <div *ngIf="!record[col.Name[0]] && !record[col.Name[1]]" style="text-align: center;">
              </div>
              <div *ngIf="record[col.Name[0]] && record[col.Name[1]]" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/email-open-outline-green.svg" width="18px" height="18px" />
              </div>
              <div *ngIf="!record[col.Name[0]] && record[col.Name[1]]" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/email-outline-red.svg" width="18px" height="18px" />
              </div>
            </div>


            <div *ngIf="col.Type === 'ftProgress'">

              <div *ngIf="record[col.Name]==='NEU'" class="row">
                <p-progressBar [value]="25" [showValue]="false" class="col-6 vsProgressGreen" inputId="progBar_new"
                  aria-labelledby="progBar_new_text" aria-hidden="true"></p-progressBar>
                <div class="vsGreenText col-6" style="margin-top: 0.2rem;" id="progBar_new_text">{{'status.new' |
                  translate}}</div>
              </div>
              <div *ngIf="record[col.Name]==='BEARB'" class="row">
                <p-progressBar [value]="75" [showValue]="false" class="col-6 vsProgressYellow" inputId="progBar_edit"
                  aria-labelledby="progBar_edit_text" aria-hidden="true"></p-progressBar>
                <div class="vsYellowText col-6" style="margin-top: 0.2rem;" id="progBar_edit_text">{{'status.wip' |
                  translate}}</div>
              </div>
              <div *ngIf="record[col.Name]==='GEN'" class="row">
                <p-progressBar [value]="100" [showValue]="false" class="col-6 vsProgressGreen" inputId="progBar_yay"
                  aria-labelledby="progBar_yay_text" aria-hidden="true"></p-progressBar>
                <div class="vsGreenText col-6" style="margin-top: 0.2rem;" id="progBar_yay_text">{{'status.approved' |
                  translate}}</div>
              </div>
              <div *ngIf="record[col.Name]==='ABG'" class="row">
                <p-progressBar [value]="100" [showValue]="false" class="col-6 vsProgressRed" inputId="progBar_nay"
                  aria-labelledby="progBar_nay_text" aria-hidden="true"></p-progressBar>
                <div class="vsRedText col-6" style="margin-top: 0.2rem;" id="progBar_nay_text">{{'status.denied' |
                  translate}}</div>
              </div>

            </div>

            <div *ngIf="col.Type === 'ftStatus'">
              <div *ngIf="record[col.Name]==='Genehmigt'" class="vsGreenText" id="status_yay_text"><b>Genehmigt</b>
              </div>
              <div *ngIf="record[col.Name]==='Abgelehnt'" class="vsRedText" id="status_nay_text"><b>Abgelehnt</b></div>
              <div *ngIf="record[col.Name]==='Neu'" id="status_new_text">Neu</div>
              <div *ngIf="record[col.Name]==='Bearbeitung'" id="status_in_progress_text">Bearbeitung</div>
            </div>

            <div *ngIf="col.Type === 'ftVerify'">
              <div *ngIf="record[col.Name]==='NOK'" class="vsGreenText" id="status_yay_text"
                style="text-align: center;"><img alt="" src="/assets/img/Icons/vs-close-thick-red.svg" width="18px"
                  height="18px" /></div>
              <div *ngIf="record[col.Name]==='OK'" class="vsRedText" id="status_nay_text" style="text-align: center;">
                <img alt="" src="/assets/img/Icons/vs-check-green-bold.svg" width="18px" height="18px" />
              </div>
              <div *ngIf="record[col.Name]==='PRUEF'" id="status_new_text" style="text-align: center;"><img alt=""
                  src="/assets/img/Icons/vs-cog-outline.svg" width="18px" height="18px" /></div>
            </div>


            <div *ngIf="col.Type === 'ftVisibility'">
              <div *ngIf="record[col.Name]==='PUBLIC'" style="text-align: center;"><img alt=""
                  src="/assets/img/Icons/vs-eye-green.svg" width="18px" height="18px" /></div>
              <div *ngIf="record[col.Name]==='INTERN'" style="text-align: center;"><img alt=""
                  src="/assets/img/Icons/vs-eye-off-red.svg" width="18px" height="18px" /></div>
            </div>

            <!-- $rev AK 2022-11-09: ftIconWarn für "OK?"-Spalte in sysViewDataVorgangList (Bestellcenter Tab:Exporte) eingeführt.
                                      Kann auch anderweitig genutzt werden. Für Name muss ein Array mit 2 Strings übergeben werden:
                                      bei o.g. Bsp: 1. "Vorg_Kenn_OK" (:boolean), 2. "Vorg_Kenn_Prot_Meld" (:boolean)
            -->
            <div *ngIf="col.Type === 'ftIconWarn'">
              <div *ngIf="!record[col.Name[0]]" style="text-align: center;">
                <img alt="All is well." src="/assets/img/Icons/vs-alert-error-outline.svg" width="18px" height="18px" />
              </div>

              <div *ngIf="record[col.Name[0]] && record[col.Name[1]]" style="text-align: center;">
                <img alt="There was a warning." src="/assets/img/Icons/vs-alert-warning-outline.svg" width="18px"
                  height="18px" />
              </div>

              <div *ngIf="record[col.Name[0]] && !record[col.Name[1]]" style="text-align: center;">
                <img alt="There was an error." src="/assets/img/Icons/vs-check-green-bold.svg" width="18px"
                  height="18px" />
              </div>
            </div>

            <!-- <div *ngIf="col.Type === 'ftBadge' && record[col.Name] > 0" class="vsAntragsTableBadge"><p-badge [value]="record[col.Name]" severity="danger"></p-badge></div> -->

          </td>
          <td *ngIf="!checkBoxesEnabled">
            <p-tableCheckbox style="display:none" [pSelectableRow]="record" [value]="record"></p-tableCheckbox>
          </td>
        </tr>
      </ng-template>

      <!-- <ng-template pTemplate="rowexpansion" let-record>
        <tr>
          <td colspan="7">
            <div class="p-p-3">
              <p-table [value]="record?.baseApplicationList" dataKey="applicationNum">
                <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="applicationStatus">Status <p-sortIcon field="applicationStatus"></p-sortIcon></th>
          <th pSortableColumn="customer">Art <p-sortIcon field="customer"></p-sortIcon></th>
          <th pSortableColumn="applicationNum">Antragsnummer <p-sortIcon field="applicationNum"></p-sortIcon></th>
   
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order let-expanded="expanded">
        <tr>
          <td>{{order.applicationStatus}}</td>
          <td>Grundantrag</td>
          <td>{{order.applicationNum}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">Keine Grundanträge zu diesem Datensatz verfügbar.</td>
        </tr>
      </ng-template>


    </p-table>
  </div>
  </td>
  </tr>
  </ng-template> -->

  <ng-template pTemplate="rowexpansion" let-record>
    <tr>
      <td colspan="8">
        <div class="p-p-3">
          <p-table [value]="record?.baseApplicationList" dataKey="applicationNum">
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="applicationStatus" style="width:110px;">Status <p-sortIcon field="applicationStatus" ></p-sortIcon></th>
                <th pSortableColumn="customer" style="width:120px;">Art <p-sortIcon field="customer"></p-sortIcon></th>
                <th pSortableColumn="applicationNum" style="width:150px;">Antragsnummer <p-sortIcon field="applicationNum"></p-sortIcon></th>
                <th pSortableColumn="applicationAttendedSchool.name1" style="width:300px;">Besuchte Schule <p-sortIcon field="applicationAttendedSchool.name1"></p-sortIcon></th>
                <th pSortableColumn="applicationValidFrom" style="width:125px;">Gültig von <p-sortIcon field="applicationValidFrom"></p-sortIcon></th>
                <th pSortableColumn="applicationValidUntil" style="width:125px;">Gültig bis <p-sortIcon field="applicationValidUntil"></p-sortIcon></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order let-expanded="expanded">
              <tr>
                <td>{{order.applicationStatus}}</td>
                <td>Grundantrag</td>
                <td>{{order.applicationNum}}</td>
                <td>{{order.applicationAttendedSchool?.name1}}</td>
                <td>{{ISO8061ToDateStr(order.applicationValidFrom)}}</td>
                <td>{{ISO8061ToDateStr(order.applicationValidUntil)}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="6">Keine Grundanträge zu diesem Datensatz verfügbar.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </td>
    </tr>
  </ng-template>

  </p-table>


  <!-- Refreshing Spinner --------------------------------------------------------------------->

  <div *ngIf="DOMain.IsRefreshing" class="vsTableHintIsRefreshing">
    <!-- <div style="padding: 2rem; margin-left: 30rem; margin-right: 30rem; border-style: solid; border-width: 1px; border-radius: 0.5rem; border-color: #ccd4da"> -->
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5"
      animationDuration="1s"></p-progressSpinner>
    <!-- </div> -->
  </div>


  <!-- Hint: No Records ----------------------------------------------------------------------->
  <div *ngIf="!DOMain.IsRefreshing && (DOMain.Dataset.Data.length == 0)" class="vsTableHintNoRecords">
    <div>- - - - - - - -&nbsp;&nbsp;{{'vsComponents.vsTable.noRecords'| translate}}&nbsp;&nbsp;- - - - - - - -</div>
  </div>


  <!-- Hint: Filter Required ------------------------------------------------------------------>

  <div
    *ngIf="HintVisibleFilterRequired && (!DOMain.IsRefreshing) && (DOMain.Dataset.Data.length == 0) && !isAlrdyFiltered"
    class="vsTableHintFilterRequired">
    <!-- <div>- - - - - - - -&nbsp;&nbsp;Bitte geben Sie mindestens ein Filterkriterium an, damit Daten angezeigt werden können&nbsp;&nbsp;- - - - - - - -</div> -->
    <div>{{'vsComponents.vsTable.filterRequired'| translate}}</div>
  </div>

</div> <!-- vsTable -->

<p-contextMenu #contextmenu [model]="MenuItemsContext" class="vsContextMenuTable"></p-contextMenu>

</div> <!-- vsViewSectionMainTable -->