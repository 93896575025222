import { Component, OnInit, Injectable, ElementRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError  } from 'rxjs/operators';


// vsLib
import * as vsDataObject                                  from '@vsLib/vsDataObject';
import * as vsDataFilter                                  from '@vsLib/vsDataFilter';
import * as vsCommon                                      from '@vsLib/vsCommon';
import * as vsGlobalResources                             from '@vsLib/Services/vsGlobalResources.service';



//
// TvsHTTPMethod
//
export enum TvsHTTPMethod {
  hmGet       = "hmGet",
  hmPut       = "hmPut",
  hmPost      = "hmPost",
  hmDelete    = "hmDelete",
} // TvsHTTPMethod



//
// TvsGetType
//
export enum TvsGetType {
  gtNewRecord = "gtNewRecord",
  gtInitList  = "gtInitList",
  gtNormal    = "gtNormal"
} // TvsGetType



//
// TvsHttpServiceComponent
//
@Component({
  selector:     'app-service',
  templateUrl:  './vsHttp.service.component.html',
  styleUrls:   ['./vsHttp.service.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class TvsHttpServiceComponent implements OnInit {


  constructor(  private    http:               HttpClient
              , private    globalResources:    vsGlobalResources.TvsGlobalResources
    ) {
  }


  ngOnInit(): void {
  }

   private AT(): string {
     //let res = localStorage.getItem('AccessToken');  // $Rev MH 2025-01-27  AccessToken
    let res =  this.globalResources.AccessToken; // $Rev MH 2025-01-27  AccessToken
     return res ? res : '';
   };


  private cvArrayToJSONString(a_StringArray: string[]): string {

    let sResult:    string  = '';

    for(let i = 0; (i < a_StringArray.length); i++) {
      sResult  = sResult + (i > 0 ? ',' : '');
      sResult  = sResult + '"' + a_StringArray[i] + '"';
    }
    sResult    = '[' + sResult + ']';

    return sResult;

  } // cvArrayToJSONString



  public dataGet( a_sDataSourceID:  string,
                  a_bKennFieldDefs: boolean = false,
                  a_QueryFields:    string[] = [],
                  // a_sFilter:        string = '',
                  a_DataFilter:     vsDataFilter.TvsDataFilter = null,
                  a_getType:        TvsGetType = TvsGetType.gtNormal,
                  a_bUseRecordBasedAccessRights: boolean = false,
                  a_bRecordcountOnly: boolean = false): Observable<any[]> {

    let sRequestFilter:             string              = '';
    let sRequestFilterPKValue:      string              = '';
    let sQueryFields:               string              = '';
// console.log('HTTP: ' + a_sDataSourceID);
    // a_bKennFieldDefs = false;

    //---- [LABOR] Filterkriterien

    // $Rev TC 2021-06-02:
    // Da die Filterkriterien momentan noch nicht von den Views gesetzt und übergeben werden, erfolgt hier vorerst
    // je nach DataSourceID eine unterschiedliche Filterung, damit die Datenmenge nicht zu groß wird.
    // if (a_sFilter === null) {
    //   switch (a_sDataSourceID) {
    //     case 'adrDOKatPersAnrede':
    //       sRequestFilter  = '';
    //       break;
    //     case 'ausbDOStammAzubi':
    //       // sRequestFilter  = '&Kontakt_Name1=Müller';
    //       sRequestFilter  = '&Kontakt_Name1=Schulz';
    //       break;
    //     case 'ausbDOStammStaett':
    //       sRequestFilter = '&AusbStaett_ID_Art=S_GRUND';
    //       break;
    //   } // switch
    // } else {
    //   sRequestFilter = a_sFilter;
    // }


    //---- Filter: PKValue -> dann werden andere Filter ignoriert

    // if ((a_sPKValue != '') && (a_sPKValue != undefined)) {
    //   sRequestFilterPKValue   = '&PKValue=' + a_sPKValue;
    // }

    // DataFilter
    let sDataFilter: string = '';
    if (vsCommon.vsVarAssigend(a_DataFilter)) {

      let i: number = 0;
      a_DataFilter.forEach(item => {
          (item as vsDataFilter.TvsDataFilterItem).Criterias.forEach(crit => {
          sDataFilter = sDataFilter + '&'
            + (crit as vsDataFilter.TvsFilterCriteria).Name
            + '='
            + (crit as vsDataFilter.TvsFilterCriteria).Value
        })
        });
      }

    //---- QueryFields

    sQueryFields    = this.cvArrayToJSONString(a_QueryFields);


    //---- URL

    // $Rev TC 2021-06-09 ---------- BEG ----------
    // Service Name kann nun parametrisiert werden. Aktuell noch hard coded über die weiter oben gesetzte Variable sRequestServiceName
    //
    // Anmerkung:
    // Eventuell sollten die Parameter IP-Adresse, Port und Service Name über Properties dieser Serviceklasse realisiert
    // werden. Zumindest IP-Adresse und Port könnten zusätzlich noch aus einer Konfigurationsdatei eingelesen werden.

    let sUserID: any = this.globalResources.UserProfile.userID === null ? '' :  this.globalResources.UserProfile.userID;

    // $Rev MH 2024-01-19  AccessToken
    // let sUserAccLoginID: any = this.globalResources.UserProfile.UserAccLoginID === null ? '' :  this.globalResources.UserProfile.UserAccLoginID;
    let sUserAccLoginID: any = this.globalResources.LoginID === null ? '' :  this.globalResources.LoginID;

    let sRecordcount: string = a_bRecordcountOnly ? '&Recordcount=true,' : '';

    // console.log('TEST###: ', (a_bKennFieldDefs ? 'true':'false'))
    let sUrl  = this.globalResources.RetrieveURLBase()
              + '?DataSourceID='          + a_sDataSourceID
              + '&FieldDefs='             + (a_bKennFieldDefs ? 'true':'false')
              + '&FieldNames=true'
              + '&UserID='                + sUserID
              + '&LoginID='               + sUserAccLoginID
              + '&UseRecordBasedAccessRights=' + (a_bUseRecordBasedAccessRights ? 'true':'false')
              + sRecordcount
              + sRequestFilter
              + sDataFilter;
              // console.log('DataFilter: ', a_DataFilter)
              // console.log('URL: ',       sUrl)
// $Rev TC 2021-06-09 ---------- END ----------


    //---- HTTP Header


    let bGet = (a_getType === TvsGetType.gtNewRecord) || (a_getType === TvsGetType.gtInitList);
//  console.log('AT: ', this.AT());
    const httpOptions = {
      headers: new HttpHeaders({
         'QueryParams':    '{' + ( bGet  ? '"GetDefaultValues" : true, ' : '') +  '"QueryFields": ' + sQueryFields + '}'
         ,'AT'        :   this.AT()
         ,'NoLogging' :   'T'
        })
    };

    // console.log('Service ==========================================');
    //  console.log('Url:                   ',  sUrl);
      //  console.log('getType: ', a_getType);
     // console.log('Host:                   ',  sRequestIPAddress + ':' + sRequestPortNumber)
      // console.log('DatasourceID:           ',  a_sDataSourceID);
      // console.log('QueryFields:            ',  a_QueryFields)
      // console.log('sRequestFilter:         ',  sRequestFilter);
      // console.log('sRequestFilterPKValue:  ',  sRequestFilterPKValue)
    //  console.log('headers:                ',  httpOptions.headers);
    // console.log('Service ==========================================');


    //---- HTTP Request abschicken

    return this.http.post<any[]>(sUrl, '', httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );

  } // dataGet



  public dataDelete(a_sDataSourceID: string, a_sPKValue: string): Observable<any[]> {

    let sRequestFilterPKValue:      string              = '';

    //---- Filter: PKValue -> dann werden andere Filter ignoriert

    if ((a_sPKValue != '') && (a_sPKValue != undefined)) {
      sRequestFilterPKValue   = '&PKValue=' + a_sPKValue;
    }

    let sUserID: any = this.globalResources.UserProfile.userID === null ? '' :  this.globalResources.UserProfile.userID;

        // $Rev MH 2024-01-19  AccessToken
    // let sUserAccLoginID: any = this.globalResources.UserProfile.UserAccLoginID === null ? '' :  this.globalResources.UserProfile.UserAccLoginID;
    let sUserAccLoginID: any = this.globalResources.LoginID === null ? '' :  this.globalResources.LoginID;


    let sUrl  = this.globalResources.RetrieveURLBase()
              + '?DataSourceID=' + a_sDataSourceID
              + '&FieldDefs=true&FieldNames=true'
              + '&UserID='                + sUserID
              + '&LoginID='               + sUserAccLoginID
              + sRequestFilterPKValue;

    //---- HTTP Request abschicken
    const httpOptions = {
      headers: new HttpHeaders({
         'AT':    this.AT()
        })
    };

    return this.http.delete<any[]>(sUrl, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );

  } // dataDelete




  public dataUpdate(a_sDataSourceID: string, a_DOMain: vsDataObject.TvsDataObject, a_sPKValue: string): Observable<any> {

    // console.log('Service dataUpdate  a_sDataSourceID',        a_sDataSourceID);
    // console.log('Service dataUpdate  a_DOMain.Dataset.Data',  a_DOMain.Dataset.Data);

    let sRequestFilterPKValue:      string              = '';
    let bTestMode:                  boolean             = false;        // $Rev TC 2021-06-13: added


    // console.log('a_DOMain: ', a_DOMain);
    // console.log('PKName:   ', a_DOMain.Dataset.PKName);
    // console.log('PKValue:  ', a_sPKValue);


    //---- URL

    let sUserID: any = this.globalResources.UserProfile.userID === null ? '' :  this.globalResources.UserProfile.userID;

    // $Rev MH 2024-01-19  AccessToken
    // let sUserAccLoginID: any = this.globalResources.UserProfile.UserAccLoginID === null ? '' :  this.globalResources.UserProfile.UserAccLoginID;
    let sUserAccLoginID: any = this.globalResources.LoginID === null ? '' :  this.globalResources.LoginID;

    sRequestFilterPKValue   = a_sPKValue === '' ? '' : '&PKValue=' + a_sPKValue;
    bTestMode               = a_DOMain.TestMode;                        // $Rev TC 2021-06-13: added

    let sUrl  = this.globalResources.RetrieveURLBase()
              + '?TestMode='      + bTestMode                           // $Rev TC 2021-06-13: added
              + '&DataSourceID='  + a_sDataSourceID
              + '&UserID='                + sUserID
              + '&LoginID='               + sUserAccLoginID
              + sRequestFilterPKValue;

              // console.log('Post - Url: ', sUrl);
              // console.log('Post - GlobalRess: ', this.globalResources.UserProfile);

    //---- HTTP Body

    const body = a_DOMain;

    const httpOptions = {
      headers: new HttpHeaders({
        'AT':    this.AT()
        })
    };


    //---- HTTP Request abschicken

    if ((a_sPKValue === null) || (a_sPKValue === '')) {

        // console.log('Put-Request aufgerufen...', body);

        return this.http.put(sUrl, body, httpOptions).pipe(
          map((data) => {
            // Process the data as needed here
            // console.log('Processed Data:', data);
            // For example, transform the data or extract specific fields
            return data; // Return the processed data
          }),
          catchError(error => {
            // Handle any errors here
            // console.error('Error occurred:', error);
            if(error.status === 403) {
              alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
              this.globalResources.UserProfile.Logout();
            }
            console.error('Error: ', error);
            alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

            return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
          })
        );
    }
    else {


      // console.log('Post-Request aufgerufen...', body);

      return this.http.post<any[]>(sUrl, body, httpOptions).pipe(
        map((data) => {
          // Process the data as needed here
          // console.log('Processed Data:', data);
          // For example, transform the data or extract specific fields
          return data; // Return the processed data
        }),
        catchError(error => {
          // Handle any errors here
          // console.error('Error occurred:', error);
          if(error.status === 403) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }

          console.error('Error: ', error);
          // alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
          alert(error.error?.Error?.ErrMsg || 'Ein unbekannter Fehler ist aufgetreten.');
          alert(error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

          return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
        })
      );
    }
  } // dataUpdate



  public HttpServiceGetRequest(a_sServiceName:  string, a_sQueryParams: string): Observable<any[]> {

    let sUrl  = this.globalResources.RetrieveURLBase(false) + a_sServiceName
                // $Rev MH 2024-01-19  AccessToken
                // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
                + '?LoginID='   + this.globalResources.LoginID

                + '&UserID='    + this.globalResources.UserProfile.userID
                + '&WebLoginID='  + this.globalResources.WebAccProfile.AccLoginID
                + '&WebKontaktID='  + this.globalResources.WebAccProfile.KontaktID
                + a_sQueryParams;
// console.log('## get : ', sUrl);
    const httpOptions = {
      headers: new HttpHeaders({
        'AT':    this.AT()
        })
    };

    return this.http.get<any[]>(sUrl, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );
  }

  public HttpServicePostRequest(a_sServiceName:  string, a_sQueryParams: string, a_sBody: string): any {

    let sUrl  = this.globalResources.RetrieveURLBase(false) + a_sServiceName
          // $Rev MH 2024-01-19  AccessToken
          // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
          + '?LoginID='   + this.globalResources.LoginID

          + '&UserID='    + this.globalResources.UserProfile.userID
                + a_sQueryParams;

    let token = this.AT();
    const httpOptions = {
      headers: new HttpHeaders({
        'AT': token,
        'Accept': 'text/html', // Specify that the client accepts HTML
        'Content-Type': 'text/plain' // Ensure to set the correct content type for your request body
        })
    };
    console.log('httpOptions:', httpOptions);

    return this.http.post<any[]>(sUrl, a_sBody, httpOptions).pipe(
      map((data) => {

        // Process the data as needed here
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError((error): any => {
        // Handle any errors here


        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

        return throwError(() => new Error(error));
      })
    );

  }

  public HttpServicePutRequest(a_sServiceName:  string, a_sQueryParams: string, a_sBody: string): any {

    let sUrl  = this.globalResources.RetrieveURLBase(false) + a_sServiceName
          // $Rev MH 2024-01-19  AccessToken
          // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
          + '?LoginID='   + this.globalResources.LoginID

          + '&UserID='    + this.globalResources.UserProfile.userID
                + a_sQueryParams;

    let token = this.AT();
    const httpOptions = {
      headers: new HttpHeaders({
        'AT': token,
        'Accept': 'text/html', // Specify that the client accepts HTML
        'Content-Type': 'text/plain' // Ensure to set the correct content type for your request body
        })
    };
    console.log('httpOptions:', httpOptions);
    console.log('sURL:',sUrl);
    console.log('sQueryParams:',a_sQueryParams);

    return this.http.put<any[]>(sUrl, a_sBody, httpOptions).pipe(
      map((data) => {

        // Process the data as needed here
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError((error): any => {
        // Handle any errors here


        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');

        return throwError(() => new Error(error));
      })
    );

  }

  // Vorübergehend hinzugefügt, weil noch keine WebAccounts behandelt werden in der Methode HttpServicePostRequest
  public HttpServicePostRequestWebAccount(a_sServiceName:  string, a_sQueryParams: string, a_sBody: string): Observable<any[]> {

    let sUrl  = this.globalResources.RetrieveURLBase(false) + a_sServiceName
               // $Rev MH 2024-01-19  AccessToken
               // + '?LoginID='   + this.globalResources.WebAccProfile.AccLoginID
                + '?LoginID='   + this.globalResources.LoginID

                + '&UserID='    + this.globalResources.WebAccProfile.KontaktID
                + a_sQueryParams;
     console.log('### URL: ', sUrl)

    console.log('Body: ', a_sBody)

    const httpOptions = {
      headers: new HttpHeaders({
        'AT': this.AT()
        })
    };

    return this.http.post<any[]>(sUrl, a_sBody, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );
  }

  public HttpServicePostRequestExtern(a_sServiceName:  string, a_sQueryParams: string, a_sBody: string): Observable<any[]> {

    let sUrl  = a_sServiceName
                + a_sQueryParams;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/xml', //<- To SEND XML
        'Accept':  'application/xml',       //<- To ask for XML
        'Response-Type': 'text'             //<- b/c Angular understands text
        ,'AT':    this.AT()
      })
    };

    return this.http.post<any[]>(sUrl, a_sBody, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error.error.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );
  }

  public dataPostRequestToExternalEndpoint(sUrl: string, data: any, httpMethod?: string) {
    // if(httpMethod === 'put') {
    //   return this.http.put(sUrl, data).pipe(
    //     map((response: any) => {
    //       // Optionally process the data here
    //       console.log('Processed Data:', response);
    //       return response; // Returning the raw data or processed data
    //     }),
    //     catchError((error: any) => {
    //       console.error('Error occurred:', error);
    //       if (error.status === 403) {
    //         alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!');
    //         // Assuming globalResources is available and has a method for logout
    //         this.globalResources.UserProfile.Logout();
    //       } else {
    //         const errorMessage = error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.';
    //         alert(errorMessage);
    //       }
    //       return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
    //     })
    //   );
    // }
    // if(httpMethod === 'post') {
      return this.http.post(sUrl, data).pipe(
        map((response: any) => {
          // Optionally process the data here
          console.log('Processed Data:', response);
          return response; // Returning the raw data or processed data
        }),
        catchError((error: any) => {
          console.error('Error occurred:', error);
          if (error.status === 403) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!');
            // Assuming globalResources is available and has a method for logout
            this.globalResources.UserProfile.Logout();
          } else {
            const errorMessage = error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.';
            alert(errorMessage);
          }
          return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
        })
      );
    // }
    // if(httpMethod !== 'post' && httpMethod !== 'put') {
    //  alert('this httpMethod is unknown for this method.');
    //  return null;
    // }
    }

  //   public dataGetRequestToEndpoint(path:  string, a_sQueryParams: string): Observable<any[]> {
  //     let sUrl  = this.globalResources.RetrieveURLBase(false) + path
  //                 // $Rev MH 2024-01-19  AccessToken
  //                 // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
  //                 + '?LoginID='   + this.globalResources.LoginID
  //                 + '&UserID='    + this.globalResources.UserProfile.userID
  //                 + a_sQueryParams;
  // // console.log('## get : ', sUrl);
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         'AT':    this.AT(),
  //         'PROG_MODULE_ID': 'AMIS7school'
  //         })
  //     };
  //     return this.http.get<any[]>(sUrl, httpOptions).pipe(
  //       map((data) => {
  //         // Process the data as needed here
  //         // console.log('Processed Data:', data);
  //         // For example, transform the data or extract specific fields
  //         return data; // Return the processed data
  //       }),
  //       catchError(error => {
  //         // Handle any errors here
  //         // console.error('Error occurred:', error);
  //         if(error.status === 403) {
  //           if(this.globalResources.UserProfile.IsLoggedIn) {
  //             alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
  //             this.globalResources.UserProfile.Logout();
  //           }
  //         }
  //         console.error('Error: ', error);
  //         alert(error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
  //         return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
  //       })
  //     );
  //   }

  public dataGetRequestToEndpoint(path:  string, a_sQueryParams: string): Observable<any[]> {
    let sUrl  = this.globalResources.RetrieveURLBase(false) + path
                // $Rev MH 2024-01-19  AccessToken
                // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
                + '?LoginID='   + this.globalResources.LoginID
                + '&UserID='    + this.globalResources.UserProfile.userID
                + a_sQueryParams;
// console.log('## get : ', sUrl);
    const httpOptions = {
      headers: new HttpHeaders({
        'AT':    this.AT(),
        'PROG_MODULE_ID': 'AMIS7school'
        })
    };
    return this.http.get<any[]>(sUrl, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );
  }

  dataPostRequestToEndpoint(path, data, a_sQueryParams, httpMethod?, hasUrlBase?, contentType?): Observable<any> {
    console.log(hasUrlBase);
    let newHasUrlBase = hasUrlBase ?? true;
    let newHttpMethod = httpMethod ?? 'put';
    console.log(newHasUrlBase);
    let sUrl  = newHasUrlBase ? this.globalResources.RetrieveURLBase(false) + path
                // $Rev MH 2024-01-19  AccessToken
                // + '?LoginID='   + this.globalResources.UserProfile.UserAccLoginID
                + '?LoginID='   + this.globalResources.LoginID
                + '&UserID='    + this.globalResources.UserProfile.userID
                + a_sQueryParams : path;
    console.log(sUrl);
    let httpOptions;
    contentType !== 'json' ?
    httpOptions = {
      headers: new HttpHeaders({
        'AT':    this.AT(),
        'PROG_MODULE_ID': 'AMIS7school'
        })
    } : httpOptions = {
      headers: new HttpHeaders({
        'AT':    this.AT(),
        'PROG_MODULE_ID': 'AMIS7school',
        "Content-Type": "application/json"
        })
    }
     ;
    sUrl = sUrl.replace('genericData/', '');
    if (newHttpMethod === 'put') {
      // console.log('Put-Request aufgerufen...', body);
      return this.http.put(sUrl, data, httpOptions).pipe(
        map((data) => {
          // Process the data as needed here
          // console.log('Processed Data:', data);
          // For example, transform the data or extract specific fields
          return data; // Return the processed data
        }),
        catchError(error => {
          // Handle any errors here
          // console.error('Error occurred:', error);
          if(error.status === 403) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
          console.error('Error: ', error);
          alert(error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
          return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
        })
      );
  } else {
    return this.http.post<any[]>(sUrl, data, httpOptions).pipe(
      map((data) => {
        // Process the data as needed here
        // console.log('Processed Data:', data);
        // For example, transform the data or extract specific fields
        return data; // Return the processed data
      }),
      catchError(error => {
        // Handle any errors here
        // console.error('Error occurred:', error);
        if(error.status === 403) {
          if(this.globalResources.UserProfile.IsLoggedIn) {
            alert('Unauthorisierter Zugriff. Loggen Sie sich erneut ein!')
            this.globalResources.UserProfile.Logout();
          }
        }
        console.error('Error: ', error);
        alert(error.error?.Error?.ErrMsg || error.error?.error?.errMsg || 'Ein unbekannter Fehler ist aufgetreten.');
        return throwError(() => new Error('Es ist ein Fehler aufgetreten'));
      })
    );
  }
  }

} // vsHttpServiceComponent


