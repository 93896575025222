
  <form [formGroup]="this.editForm">

      <vsLayoutGroup class="col-12" Orientation="horizontal" MediumGutterWidth=true>

        <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

        <vsLayoutGroup class="col-lg-6 col-md-8 col-sm-12" Orientation="horizontal">

          <vsLayoutGroup class="col-12">
            <div #explanation_header class="vsFormTextDiv">
              <b>{{'explanation.basic_application_heading'| translate}}</b>
            </div>
            <div #explanation_text class="vsFormTextDivParagraph vsMb-2">
              {{'explanation.basic_application_text'| translate}}
            </div>
          </vsLayoutGroup>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.heading_application_data'| translate}}" Orientation="horizontal">

            <!-- <vsLayoutGroup class="col-12">
              <vsInputAutoComplete FrmCtlName="Art_Name" label="Antragsart" [suggestions]="lookupArtName" placeholder="{{'application.input_required'| translate}}" FieldName="Art_Name" MinLength=1 Dropdown=true ></vsInputAutoComplete>
            </vsLayoutGroup> -->
            <!-- <p>Grundantrag</p> -->
            
            <vsLayoutGroup class="col-12">
              <vsInputDateTime FrmCtlName="Antr_Datum"  label="{{'application.application_date'| translate}}" labelWidth="230px" Disabled="true"></vsInputDateTime>
            </vsLayoutGroup>

            <vsLayoutGroup class="col-12">
              <vsInputDateTime FrmCtlName="Datum_Guelt_Beg"  label="{{'application.school_attendance_start'| translate}}" labelWidth="230px" placeholder="{{'application.input_required'| translate}}"></vsInputDateTime>
            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>  

        <vsLayoutGroup class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

        <div class="vsMb-2"></div>

        <vsLayoutGroup  *ngIf="KennTransportOptions" class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

        <vsLayoutGroup  *ngIf="KennTransportOptions" class="col-lg-6 col-md-8 col-sm-12" Orientation="horizontal">

          <vsLayoutGroup class="col-12">
            <div #explanation_header2 class="vsFormTextDiv">
              <b>{{'explanation.basic_application_transp_heading'| translate}}</b>
            </div>
            <div #explanation_text2 class="vsFormTextDivParagraph vsMb-2" [innerHTML]="'explanation.basic_application_transp_text' | translate">
            </div>
            <!-- Anzeige der Transport-Information basierend auf der Auswahl -->
            <div class="vsFormTextDivParagraph vsInfoText vsMt-2 vsMb-2" *ngIf="selectedTransportInfo" [innerHTML]="selectedTransportInfo"> 
            </div>

          </vsLayoutGroup>

          <div class="vsMb-2"></div>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.information_about_transport'| translate}}" Orientation="horizontal">

            <vsLayoutGroup class="col-12">
              <vsInputAutoComplete FrmCtlName="Transp_Art" label="{{'application.planned_type_of_transport'| translate}}" labelWidth="230px" [suggestions]="translationLookupTranspArtTranslated" placeholder="{{'application.input_required'| translate}}" FieldName="Name" MinLength=1 Dropdown=true ></vsInputAutoComplete>
            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>  

        <vsLayoutGroup  *ngIf="KennTransportOptions" class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

        <div class="vsMb-2"></div>

        <vsLayoutGroup  *ngIf="KennApplicationReason" class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

        <vsLayoutGroup  *ngIf="KennApplicationReason" class="col-lg-6 col-md-8 col-sm-12" Orientation="horizontal">

          <vsLayoutGroup class="col-12">
            <div #explanation_header2 class="vsFormTextDiv">
              <b>{{'explanation.basic_application_reason_heading'| translate}}</b>
            </div>
            <div #explanation_text2 class="vsFormTextDivParagraph vsMb-2" [innerHTML]="'explanation.basic_application_reason_text' | translate">
            </div>
          </vsLayoutGroup>

          <div class="vsMb-2"></div>

          <vsLayoutGroup class="col-12" isPanel=true panelTitleVisible=true panelTitleText="{{'application.information_about_reason'| translate}}" Orientation="horizontal">

            <vsLayoutGroup class="col-12">
              <vsInputAutoComplete FrmCtlName="Transp_Reason" label="{{'application.application_reason'| translate}}" labelWidth="230px" [suggestions]="translationLookupApplicationReasonTranslated" placeholder="{{'application.input_required'| translate}}" FieldName="Name" MinLength=1 Dropdown=true ></vsInputAutoComplete>
            </vsLayoutGroup>

          </vsLayoutGroup>

        </vsLayoutGroup>  

        <vsLayoutGroup  *ngIf="KennApplicationReason" class="col-lg-3 col-md-2 col-sm-0" Orientation="horizontal">
        </vsLayoutGroup>  

    </vsLayoutGroup>

  </form>

  <div style="margin-top: 1rem; margin-bottom: 1rem;">
      <button pButton label="{{'application.button_back'| translate}}" (click)="actionStepPrev()" class="vsElternButton vsBack" style="margin-right: 10px;"></button>
      <button pButton label="{{'application.button_forward'| translate}}" (click)="actionStepNext()" class="vsElternButton vsForward"></button>
  </div>
  
  

  <p-dialog header="{{'dialog.error_fill_heading'| translate}}" [(visible)]="displayDialogError" [baseZIndex]="10000" class="vsDialog vsDanger vsRequired">
    <p [innerHtml]="'dialog.error_fill_text'| translate"></p>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="displayDialogError=false" label="{{'dialog.error_fill_button_ok'| translate}}" class="vsToolbarItem vsToolbarItemOK vsMb-2">
          <span>
            <img alt="close" src="/assets/img/Icons/vs-check-circle.svg" />
          </span>
        </button>
    </ng-template>
  </p-dialog>
  


 