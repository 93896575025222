//--------------------------------------------------------------------------------------------------
//  AMIS7core
//
//  Domain:   Elternportal
//  Title:    Antragsliste
//  Notes:
//
//
//  H I S T O R Y
//
//  2021-06-15  TC   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component
        , OnInit
        , ComponentFactoryResolver,
        AfterViewInit,
        ViewChild}         from '@angular/core';

import { Router }                           from '@angular/router';


// PrimeNG
import { MenuItem, MessageService }                         from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';

import * as vsViewDataList                      from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { TvsHttpServiceComponent }          from '@vsLib/Services/vsHttp.service.component';
// import { TvsActionList }                    from '@vsLib/vsActionList';

// [ngx-translate]
import { LangChangeEvent, TranslateService }                 from '@ngx-translate/core';

// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { TvsViewDataList }                      from '@vsLib/View/components/vsViewDataList/vsViewDataList.component';
import { TvsDataObject }                    from '@vsLib/vsDataObject';
import { TvsTableComponent }                from '@vsLib/Components/vsTable/vsTable.component';
import * as vsTable                         from '@vsLib/Components/vsTable/vsTable.component';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';
import { TvsView } from '@vsLib/View/components/vsView/vsView.component';
import { TvsViewData } from '@vsLib/View/components/vsViewData/vsViewData.component';
import { TvsDataFilter } from '@vsLib/vsDataFilter';

type TranslationItemsType = {
  translationLabel: string;
  changeReason: string;
  route: string;
  icon: string;
}

type ChangeReasonDataType = {
  label: string;
  icon: string;
  command: any;
}

interface AzubiNutzungsDaten {
    browser: {id: number};
    device: {id: number};
    onlineService: {id: number};
    processBegin: string;
    processEnd: string;
    language: string;
    transmissionType: {id: number};
    dataSource: string;
    operatingSystem: {id: number};
    paymentType: {id: number};
    authenticationSource: {id: number};
    processStatus: {id: number};
};


@Component({
  selector:       'schoolViewAntragList',
  templateUrl:  './schoolViewAntragList.component.html',
  styleUrls:   ['./schoolViewAntragList.component.scss']
})
export class TschoolViewAntragList extends vsViewDataList.TvsViewDataList implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // ./.


  //====== Properties (PUBLIC)

  public      MenuUser:                       MenuItem[];                                     // PrimeNG
  public      dataObject:                     TvsDataObject                                   = new TvsDataObject();
  public      changeReasonData:               ChangeReasonDataType[]                          = [];
  public      translationItems:               TranslationItemsType[]                          = [];
  public      disabled:                       boolean                                         = true;
  public browserTypes = {
    "Chrome": 1,
    "Firefox": 2,
    "Safari": 3,
    "Edge": 4,
    "Andere": 5,
    "Nicht bestimmbar": 6
  };

  public deviceTypes = {
    "Mobile": 1,
    "Tablet": 2,
    "Desktop": 3,
    "Andere": 4,
    "Nicht bestimmbar": 5
  };

  public operatingSystems = {
    "Windows": 1,
    "macOS": 2,
    "iOS": 3,
    "Android": 4,
    "Linux": 5,
    "Andere": 6,
    'Nicht bestimmbar': 7
  };


  @ViewChild('table') table: TvsTableComponent;

  //====== Constructor

  constructor(      protected router:                   Router
    , protected globalResources:          vsGlobalResources.TvsGlobalResources
    , protected HttpServiceComponent:     TvsHttpServiceComponent
    , protected componentFactoryResolver: ComponentFactoryResolver
    , public    dataModel:                schoolDataModel.TschoolDataModel
    , protected messageService:           MessageService
    , public    translate:                TranslateService      // [ngx-translate]
  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance      = this;
    this.ViewProperties.ViewTitle     = 'Anträge';

    this.ViewProperties.ProgFuncID    = null;
    this.ViewProperties.DataSourceID  = 'schoolDOStammAntrag';
    this.ViewProperties.QueryFields   = [];

      //$REV TE  2022-07-21: Übersetzungen des Menüs mit den Änderungsgründen
      // this.translationItems = [
      //   {translationLabel:'antrlist.menu_change.student_address', changeReason: 'student_address', route: 'editStudentAddress',    icon: 'vsUserIcon'},
      //   {translationLabel:'antrlist.menu_change.student_name',    changeReason: 'student_name',    route: 'editStudentName',       icon: 'vsUserIcon'},
      //   {translationLabel:'antrlist.menu_change.leg_rep_contact', changeReason: 'vertret_address', route: 'editVertretungAddress', icon: 'vsUserIcon'},
      //   {translationLabel:'antrlist.menu_change.leg_rep_bank',    changeReason: 'bank',            route: 'editBankverb',          icon: 'vsEuroIcon'},
      //   {translationLabel:'antrlist.menu_change.school',          changeReason: 'school',          route: 'editSchule',            icon: 'vsTownHall'},
      //   //$REV MP 2022-11-07: Wurde mit dem schoolEdit zusammengefasst.
      //   // {translationLabel:'antrlist.menu_change.school_kind', changeReason: 'school_type', route: 'editSchulart', icon: 'vsTownHall'}
      // ];

      //Menü initial in der Startsprache erstellen.
      // this.translationItems.map((item) => {
      //   this.translate.get(item.translationLabel).subscribe((res)=>{
      //     this.changeReasonData.push(
      //        {label: res,
      //         icon: item.icon,
      //         command: event => {
      //           if(!Array.isArray(this.table.dataSelected) ) {

      //           this.dataModel.antr_ID_Change_Reason = item.changeReason;
      //           this.dataModel.PKValue = this.table.dataSelected.PKValue;

      //           this.router.navigateByUrl(item.route);}
      //         }
      //       });
      //   });
      // });
  } // constructor



  //====== Methods: NG event handler

  getBrowserId(userAgent: string): number {
    if (/chrome/i.test(userAgent) && !/edg/i.test(userAgent)) {
      return this.browserTypes.Chrome;
    } else if (/firefox/i.test(userAgent)) {
      return this.browserTypes.Firefox;
    } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
      return this.browserTypes.Safari;
    } else if (/edg/i.test(userAgent)) {
      return this.browserTypes.Edge;
    } else {
      return this.browserTypes.Andere;
    }
  }

  getDeviceType() {
    const ua = navigator.userAgent;

    if (/tablet|ipad|playbook|silk/i.test(ua)) {
        return this.deviceTypes.Tablet;
    }
    if (/mobile|iphone|ipod|iemobile|blackberry|opera mini/i.test(ua)) {
        return this.deviceTypes.Mobile;
    }
    if (/msie|trident|edge|edg/i.test(ua) || /firefox|fxios/i.test(ua) || /chrome|crios|chromium|brave|opera|opr/i.test(ua)) {
      return  this.deviceTypes.Desktop;
    }
  // Return 'Andere' for uncommon or undetected devices
    return 4; // Andere
  }

  getOperatingSystem() {
    const ua = navigator.userAgent;

    if (/windows phone|windows/i.test(ua)) {
      return this.operatingSystems.Windows;
    }
    if (/macintosh|mac os x/i.test(ua)) {
      return this.operatingSystems.macOS;
    }
    if (/android/i.test(ua)) {
      return this.operatingSystems.Android;
    }
    if (/linux/i.test(ua)) {
      return this.operatingSystems.Linux;
    }
    if (/iphone|ipad|ipod/i.test(ua)) {
      return this.operatingSystems.iOS;
    }
    // Return 'Andere' for uncommon or undetected operating systems
    return this.operatingSystems.Andere;
  }

  convertDate(dateString) {
    // Create a new Date object using the input string
    const date = new Date(dateString);
  
    // Extract the year, month, and day from the Date object
    const year = date.getFullYear(); // yyyy
    let month: string | number  = date.getMonth() + 1; // getMonth() returns month from 0-11 (0 = January, 11 = December)
    let day: string | number  = date.getDate(); // getDate() returns day of the month
    let hours: string | number = date.getHours(); // getHours() returns hour of the day
    let minutes: string | number  = date.getMinutes(); // getMinutes() returns minutes
    let seconds: string | number  = date.getSeconds(); // getSeconds() returns seconds
  
    // Ensure the month, day, hours, minutes, and seconds are two digits by adding a leading zero if necessary
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
  
    // Format the date and time in yyyy-mm-ddTHH:MM:SS format
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  
    return formattedDateTime;
  }

  // Anpassung der Schnittstelle eGovSAD von de-DE auf de
  // Ticketnummer: KT100120-927
  mapLanguage = (lang) => {
    if(lang === 'de-DE') {
      lang = 'de';
    }
    if(lang === 'en-GB') {
      lang = 'en';
    }
    if(lang === undefined || lang === null) {
      lang = '';
    }
    return lang;
  }
  


  ngOnInit(): void {
    // this.DataFilter.ItemsClear();

    // let item = this.DataFilter.ItemAdd('Filter Azubi_ID_Gesetzl_Vertret');
    // let item = this.DataFilter.ItemAdd('Filter Antragsteller');

    // item.CriteriaAdd('Azubi_ID_Gesetzl_Vertret', this.globalResources.WebAccProfile.KontaktID);

    // item.CriteriaAdd('Antr_ID_Kontakt_AntrStell', this.globalResources.WebAccProfile.KontaktID);

    // let item2 = this.DataFilter.ItemAdd('Filter Visibility = Public');
    // item2.CriteriaAdd('Vorg_ID_Visibility', 'PUBLIC');
    const azubiNutzungsDaten: AzubiNutzungsDaten = {
      browser: {id : this.getBrowserId(navigator.userAgent)},
      device: {id: 5},
      // onlineService: {id: 354}, // Vorgabe aus Dok
      //$REV MP 2024_12_16: Wert aus der app.config laden lassen 
      onlineService: {id: this.globalResources.AppConfigData?.eGovSadOnlineID}, // Wird aus app.config.json geladen
      processBegin: this.convertDate(new Date()),
      processEnd: null,
      language: this.mapLanguage(this.globalResources.AppInfo.selectedLang),
      transmissionType: {id: 3},  // id 3 => API
      dataSource: 'Vedisys Schülerbeförderung', // Example source, replace as needed
      operatingSystem: {id: this.getOperatingSystem()},
      paymentType: {id: 12}, // id 12 => Keine Zahlung
      authenticationSource: {id: 7}, // id 7 => Spez-ID
      processStatus: null
    };
    this.dataModel.azubiNutzungsDaten = azubiNutzungsDaten;

    this.MenuUser = [
        {label: 'Mein Profil',    icon: 'pi pi-fw pi-user',       command: event => {this.mnuUserMyProfile_OnClick()}}
      , {label: 'Einstellungen',  icon: 'pi pi-fw pi-cog',        command: event => {this.mnuUserConfig_OnClick()}}
      , {separator: true}
      , {label: 'Abmelden',       icon: 'pi pi-fw pi-power-off',  command: event => {this.actionLogout()}}
    ]; // User Menu

    this.translateMenuItems();
  } // ngOnInit

  ngAfterViewInit(): void {
    this.initialiseTable('schoolDODataAntrVorgSumPortal');
  }

  translateMenuItems() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

      //changeReasonData initial leeren, damit die übersetzten Items nicht nur angehängt werden.
      this.changeReasonData = [];

      this.translationItems.map((item) => {
        this.translate.get(item.translationLabel).subscribe((res)=>{
          this.changeReasonData.push(
             {label: res,
              icon: item.icon,
              command: event => {
              if(!Array.isArray(this.table.dataSelected) ) {
                this.dataModel.antr_ID_Change_Reason = item.changeReason;
                this.dataModel.PKValue = this.table.dataSelected.PKValue;
                this.dataModel.antrag.Antr_ID_Art = this.tableList.dataSelected.Antr_ID_Art;
                this.router.navigateByUrl(item.route);}
              }
            });
        });
      });
    });
  }



  doCustomButtonClick(event: any) {
    this.actionMessageList(event.event);
  }

  public initialiseTable(DO: string): void {
    //GDS-Aufru  11 [OK] schoolDODataAntrVorgSumPortal

    // this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrVorgSumPortal', '&Antr_ID_Kontakt_AntrStell='+this.globalResources.WebAccProfile.KontaktID)
    this.HttpServiceComponent.HttpServiceGetRequest('school/data/antrVorgSumPortal', '')
    // this.HttpServiceComponent.dataGet(DO, false, [], DataFilter)
        .subscribe(data => {


          //$REV MP 2023-02-20: Value für die Badges wird übergeben, wird in den FieldDefs in OpenInEdit / ftBadge verarbeitet
          data['Dataset'].Data.map((value) =>{
            value.OpenInEdit = value.Vorg_Aus_Anz_Ungelesen;
          });

          if(data['Dataset'].Data.length === 0) {
            this.disabled = true;
          } else {
            this.disabled = false;
          }


          this.table.DOMain.Dataset.FieldDefs = [
              { Name: "Antr_Nr",              Type: "ftString",    Caption: "antrlist.columns.application_number",   Size: "100",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft }
            , { Name: "Antr_Datum_Antrag",    Type: "ftDate",      Caption: "antrlist.columns.date",                 Size: "140",   Sort: false, Alignment: vsCommon.TvsAlignment.alLeft }
            , { Name: "xAzubi_Name",          Type: "ftString",    Caption: "antrlist.columns.name",                 Size: "220",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft }
            , { Name: "Art_Name",             Type: "ftString",    Caption: "antrlist.columns.type",                 Size: "180",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft }
            , { Name: "Antr_ID_Status",       Type: "ftProgress",  Caption: "antrlist.columns.status",               Size: "290",  Sort: false, Alignment: vsCommon.TvsAlignment.alLeft }
            , { Name: "OpenInEdit",           Type: "ftBadge",     Caption: "",                                      Size: "80",   Sort: false, Alignment: vsCommon.TvsAlignment.alLeft
            , ImgSrc: "/assets/img/Icons/email-outline-grey.svg",  CssClass: "vsListButton", Action: "MessageOpen"}
          ];
          this.table.DOMain.Dataset.Data = data['Dataset'].Data;
          this.table.dataSelected = data['Dataset'].Data[0];
          //TODO MP: Abfrage nach altem Antrag / und Zeitpunkt der Webaccount-Erstellung - Steuerung des Ansichtsbuttons.
        });
    }


    actionMessageList(data: any): void  {

      this.dataModel.antragCRMTicketID = data?.Antr_ID_CRM_Vorf;
      this.dataModel.antrag = data;
      this.router.navigateByUrl('/messages');

    }


    public doTableRowSelect(args: vsTable.TvsTableComponentEventArgs) {

      this.dataModel.antragCRMTicketID = args.Data.Antr_ID_CRM_Vorf;
      this.dataModel.antrag = args.Data;

  } // doTableRowSelect


  public actionAntragView(): void {
    this.dataModel.antrag = this.table.dataSelected;
    this.router.navigateByUrl('antrView');
  }



  //------------------------------------------------------------------------------------------------
  // Method:  mnuUserMyProfile_OnClick
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlung: User Menu Items
  //------------------------------------------------------------------------------------------------

  public mnuUserMyProfile_OnClick(): void {
    alert('Mein Benutzerprofil');
  } // mnuUserMyProfile_OnClick


  public mnuUserConfig_OnClick(): void {
    alert('Einstellungen');
  } // mnuUserConfig_OnClick


  public mnuUserLogout_OnClick(): void {
//    this.globalResources.UserProfile.Logout();
    this.globalResources.WebAccProfile.Logout();
  } // mnuUserLogout_OnClick


  // $Rev TC 2021-07-30
  //
  // Das wird von dem vorübergehenden zusätzlichem Button "Abmelden" aufgerufen.
  // Dieser Button ist momentan erforderlich, da das User Menu aktuell nicht funktioniert (wegen a href)
  public actionLogout(): void {
//    this.globalResources.UserProfile.Logout();
    this.globalResources.WebAccProfile.Logout();
    this.router.navigateByUrl('/home');
  } // actionLogout


    //====== [MOCKUP] [PORTAL_ELTERN] ================================================================



  // $Rev TC 2021-08-05: Labor Mockup ----------------- BEG

  public actionAntragNew(): void {
    // this.router.navigateByUrl('/antragEdit');
    this.router.navigateByUrl('landing');
  } // actionAntragNew

  public actionAntragEdit(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Antrag: Bearbeiten'});
  } // actionAntragEdit

  public actionAntragStorno(): void {
    this.messageService.add({severity: 'warn',  summary: 'Work In Progress',  detail: 'Antrag: Kündigen'});
  } // actionAntragStorno


} // schoolViewAntragList


