//--------------------------------------------------------------------------------------------------
//  AMIS7school - Externes Portal: Eltern
//
//  Domain:   School
//  Title:    Grundantrag Schülerbeförderung (Edit View)
//  Notes:    MOCKUP
//
//
//  H I S T O R Y
//
//  2022-10-05  MP   Initial Version
//--------------------------------------------------------------------------------------------------

import {  Component, ViewChild
  , OnInit
  , ComponentFactoryResolver,
  ElementRef}         from '@angular/core';

import { Router }                           from '@angular/router';

import { LangChangeEvent, TranslateService }    from '@ngx-translate/core';

import { FormGroup, FormControl, Validators }           from '@angular/forms';

import  * as schoolDataModel                from 'AMIS7school_Portal_Eltern/src/app/school/schoolDataModel.service';

import {  forkJoin }                        from 'rxjs';

// PrimeNG
import { MenuItem }                         from 'primeng/api';
import { MessageService }                   from 'primeng/api';


// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsView                          from '@vsLib/View/components/vsView/vsView.component';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import * as vsTabControl                    from '@vsLib/Components/vsTab/vsTabControl.component';

import { TvsHttpServiceComponent, 
   TvsGetType }                       from '@vsLib/Services/vsHttp.service.component';


// AMIS7core
import * as AMIS7coreConst                  from '@AMIS7core/$Common/comConst';
import { formatDate } from '@angular/common';

@Component({
selector:       'TchoolViewAntragEdit_Antrag_Grundantrag',
templateUrl:  './schoolViewAntragEdit_Antrag_Grundantrag.component.html',
})
export class TschoolViewAntragEdit_Antrag_Grundantrag extends vsView.TvsView implements OnInit {

public editForm: FormGroup = new FormGroup({});

public lookupAntrag: any[];
public lookupAntrGrund: any[] = [];
public lookupBefoerdArt: any[] = [];

public translationLookupTranspArtTranslated: any[] = [];
public translationLookupApplicationReasonTranslated: any[] = [];

public displayDialogError: boolean = false;
public KennTransportOptions: boolean = false;
public KennApplicationReason: boolean = false;

public selectedTransportInfo: string = ''; // Neue Variable für die Anzeige der Information

constructor(protected router: Router,
        public globalResources: vsGlobalResources.TvsGlobalResources,
        public dataModel: schoolDataModel.TschoolDataModel,
        protected messageService: MessageService,
        protected HttpServiceComponent: TvsHttpServiceComponent,
        public translate: TranslateService) {
super(router, globalResources);

this.ViewProperties.Instance = this;
this.ViewProperties.ViewTitle = 'Antragsauswahl';
this.ViewProperties.ProgFuncID = null;
this.ViewProperties.DataSourceID = null;
this.ViewProperties.QueryFields = [];

if (vsCommon.vsVarAssigend(this.dataModel.KennTransportOptions)) {
this.KennTransportOptions = this.dataModel.KennTransportOptions;
}

if (vsCommon.vsVarAssigend(this.dataModel.KennApplicationReason)) {
this.KennApplicationReason = this.dataModel.KennApplicationReason;
}
}

async dataLoad() {
const schoolDOKatAntragGrund = this.HttpServiceComponent.dataGet('schoolDOKatAntragGrund', false, [], null, TvsGetType.gtNormal, false);
const schoolDOKatBefoerderungArt = this.HttpServiceComponent.dataGet('schoolDOKatBefoerderungArt', false, [], null, TvsGetType.gtNormal, false);

forkJoin([schoolDOKatAntragGrund, schoolDOKatBefoerderungArt]).subscribe((results: any) => {
this.lookupAntrGrund = results[0]['Dataset'].Data.map((item: any) => ({
  Name: item.Grund_Beschreib,
  Value: item.Grund_Name,
  PKValue: item.PKValue,
  Grund_Kuerzel: item.Grund_Kuerzel,
  Art_Beschreib: item.Art_Beschreib
}));

this.lookupBefoerdArt = results[1]['Dataset'].Data.map((item: any) => ({
  Name: item.Art_Beschreib,
  Value: item.Art_Name,
  PKValue: item.PKValue,
  Art_Kuerzel: item.Art_Kuerzel,
  Art_Beschreib: item.Art_Beschreib
}));

this.translateItems();
this.prefillDropdowns(); // Call the prefill after translation and lookups are loaded
this.ViewProperties.SubjInitLoad.next();
});
}

ngOnInit(): void {
if (!vsCommon.vsStringHasValue(this.dataModel.antrag_Datum)) {
this.dataModel.antrag_Datum = vsCommon.RetrieveDateStrNow();
}

this.editForm.addControl('Antr_Datum', new FormControl(this.dataModel.antrag_Datum));
this.editForm.addControl('Datum_Guelt_Beg', new FormControl(this.dataModel.antr_Datum_Guelt_Beg));

if (this.KennTransportOptions) {
this.editForm.addControl('Transp_Art', new FormControl(this.dataModel.transp_Art));
this.editForm.get('Transp_Art')?.valueChanges.subscribe(value => this.onTransportChange(value));
}

if (this.KennApplicationReason) {
this.editForm.addControl('Transp_Reason', new FormControl(this.dataModel.transp_Reason));
}

this.dataLoad();

// Sprache automatisch aktualisieren, wenn sich die Sprache ändert
this.translate.onLangChange.subscribe(() => {
  this.translateItems();
  this.updateSelectedValues(); // Neu gewählte Werte übersetzen
});
}

// Aktualisiert bereits gewählte Werte beim Sprachwechsel
updateSelectedValues(): void {
  if (this.KennTransportOptions) {
    const currentValue = this.editForm.get('Transp_Art')?.value;
    if (currentValue) {
      const updatedValue = this.translationLookupTranspArtTranslated.find(item => item.PKValue === currentValue.PKValue);
      if (updatedValue) {
        this.editForm.get('Transp_Art')?.setValue(updatedValue);
      }
    }
  }

  if (this.KennApplicationReason) {
    const currentValue = this.editForm.get('Transp_Reason')?.value;
    if (currentValue) {
      const updatedValue = this.translationLookupApplicationReasonTranslated.find(item => item.PKValue === currentValue.PKValue);
      if (updatedValue) {
        this.editForm.get('Transp_Reason')?.setValue(updatedValue);
      }
    }
  }

  // Falls ein Transport-Hinweistext aktiv ist, diesen ebenfalls neu übersetzen
  if (this.selectedTransportInfo) {
    this.selectedTransportInfo = this.translate.instant(this.selectedTransportInfo);
  }
}

onTransportChange(value: any): void {
  if (!value || !value.Art_Beschreib) {
    this.selectedTransportInfo = '';
    return;
  }

  // Extrahiert die Nummer aus 'application.transp_Option_X'
  const optionKey = value.Art_Beschreib.replace('application.transp_Option_', '');

  // Generiert den entsprechenden Schlüssel für die Übersetzung
  const translationKey = `application.info_transp_Option_${optionKey}`;

  // Holt die übersetzte Information
  this.selectedTransportInfo = this.translate.instant(translationKey) || '';
}

prefillDropdowns(): void {
if (this.KennTransportOptions) {
const transpArtValue = this.translationLookupTranspArtTranslated.find(item => item.PKValue === this.dataModel.transp_Art);
if (transpArtValue) {
  this.editForm.get('Transp_Art')?.setValue(transpArtValue);
}
}

if (this.KennApplicationReason) {
const transpReasonValue = this.translationLookupApplicationReasonTranslated.find(item => item.PKValue === this.dataModel.transp_Reason);
if (transpReasonValue) {
  this.editForm.get('Transp_Reason')?.setValue(transpReasonValue);
}
}
}

translateItems() {
if (this.KennTransportOptions) {
this.translationLookupTranspArtTranslated = this.lookupBefoerdArt.map((item) => ({
  Name: this.translate.instant(item.Name),
  Value: item.Value,
  PKValue: item.PKValue,
  Art_Kuerzel: item.Art_Kuerzel,
  Art_Beschreib: item.Art_Beschreib
}));
}

if (this.KennApplicationReason) {
this.translationLookupApplicationReasonTranslated = this.lookupAntrGrund.map((item) => ({
  Name: this.translate.instant(item.Name),
  Value: item.Value,
  PKValue: item.PKValue,
  Grund_Kuerzel: item.Grund_Kuerzel,
  Art_Beschreib: item.Art_Beschreib
}));
}

this.translate.onLangChange.subscribe(() => {
if (this.KennTransportOptions) {
  this.translationLookupTranspArtTranslated = this.lookupBefoerdArt.map((item) => ({
    Name: this.translate.instant(item.Name),
    Value: item.Value,
    PKValue: item.PKValue,
    Art_Kuerzel: item.Art_Kuerzel,
    Art_Beschreib: item.Art_Beschreib
  }));

  this.prefillDropdowns(); // Refill dropdowns on language change
}

if (this.KennApplicationReason) {
  this.translationLookupApplicationReasonTranslated = this.lookupAntrGrund.map((item) => ({
    Name: this.translate.instant(item.Name),
    Value: item.Value,
    PKValue: item.PKValue,
    Grund_Kuerzel: item.Grund_Kuerzel,
    Art_Beschreib: item.Art_Beschreib
  }));

  this.prefillDropdowns(); // Refill dropdowns on language change
}
});
}

public actionStepPrev(): void {
this.dataSave();
this.dataModel.EmptyData();
this.router.navigate(['landing']);
}

public actionStepNext(): void {
let bOK: boolean = true;

if (bOK) {
this.editForm.get('Datum_Guelt_Beg')?.setValidators(Validators.required);
this.editForm.get('Datum_Guelt_Beg')?.updateValueAndValidity();

if (this.KennTransportOptions) {
  this.editForm.get('Transp_Art')?.setValidators(Validators.required);
  this.editForm.get('Transp_Art')?.updateValueAndValidity();
}

if (this.KennApplicationReason) {
  this.editForm.get('Transp_Reason')?.setValidators(Validators.required);
  this.editForm.get('Transp_Reason')?.updateValueAndValidity();
}

bOK = !this.editForm.invalid;
this.displayDialogError = !bOK;
}

if (bOK) {
this.dataSave();
this.router.navigate(['antragEdit/studentSelection']);
}
}

public dataSave(): void {
this.dataModel.antr_Datum_Guelt_Beg = this.editForm.controls['Datum_Guelt_Beg'].value;
this.dataModel.antrag_Datum = this.editForm.controls['Antr_Datum'].value;

if (this.KennTransportOptions) {
this.dataModel.transp_Art = this.editForm.controls['Transp_Art'].value?.PKValue;
this.dataModel.transp_Art_Rec = this.editForm.controls['Transp_Art']?.value;
}

if (this.KennApplicationReason) {
this.dataModel.transp_Reason = this.editForm.controls['Transp_Reason'].value?.PKValue;
this.dataModel.transp_Reason_Rec = this.editForm.controls['Transp_Reason']?.value;
}

}
}
