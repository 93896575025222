//--------------------------------------------------------------------------------------------------
//  vedisys Library
//
//  Title:  vsTable
//  Notes:  vsTable: Component
//
//
//  H I S T O R Y
//
//  2021-03-09  TC   Genesis
//  2023-03-07  MH   $Rev BugFix: DoOnRowSelect emmitted mehrere Events bei einem Aufruf
//--------------------------------------------------------------------------------------------------

import {
  Component
  , OnInit
  , EventEmitter
  , Input
  , ViewChild
  , TemplateRef
  , Output,
  AfterViewInit
}                             from '@angular/core';

import { vsVarAssigend }      from '@vsLib/vsCommon';

import { ISO8061ToDateStr } from '@vsLib/vsCommon'; // Import der Funktion


// vsLib
import * as vsDataObject      from '@vsLib/vsDataObject';

import { MenuItem
  , MessageService
}                             from 'primeng/api';

import * as vsCommon          from '@vsLib/vsCommon';
import * as vsGlobalResources               from '@vsLib/Services/vsGlobalResources.service';
import { trigger,state,style,transition,animate } from '@angular/animations';


//
// TvsTableComponentEventArgs
//
// Event arguments for vsTable events
//


export class TvsTableComponentEventArgs {

  public readonly Data:     any = null;
  public readonly PKValue:  any = null;

  constructor(a_data: any
    , a_sPKValue: string
  ) {
    this.Data     = a_data;
    this.PKValue  = a_sPKValue;
  } // constructor

} // TvsAppDispatcherEventArgs


//
// TvsTableComponent
//
// vsLib Wrapper für die PrimeNG table
//
@Component({
  selector:      'vsTable',
  templateUrl: './vsTable.component.html',
  styleUrls: [ './vsTable.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
]
})
export class TvsTableComponent implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  private FPKValue: string = null;


  ISO8061ToDateStr = ISO8061ToDateStr; // Funktion aus der vsCommon importieren und für das Template zugänglich machen, damit es im HTML verwendet werden kann.


  //====== Properties (PROTECTED)

  // ./.


  //====== Properties (PUBLIC)

  // [LABOR]
  // ToDo: Auslagern in eigene Klasse (TvsDataObject? Mit Properties ColumnDefs, Data, PKName etc.)

  // @Input()    ColumnDefs:                   any                           = [];
  // @Input()    Data:                         any                           = [];
  @Input() DOMain:                            vsDataObject.TvsDataObject = null;

  // TEST
  @Input() templateTitle:                     TemplateRef<any>;

  @Input() dataSelected:                      any = [];

  public SortDefs:                            any = [];

  @Input() HintVisibleFilterRequired:         boolean = false;
  @Input() KennEmitOnlyWhenNewRecordSelected: boolean = true;

  @Input() MenuItemsContext:                  MenuItem[];

  @Input() reorderableColumns:                boolean = false;
  @Input() resizableColumns:                  boolean = false;



  @Input() isExpanded:                        boolean = false;
  @Input() dataArray:                         any[] =  [
      {
        "id": "1000",
        "code": "f230fh0g3",
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": "bamboo-watch.jpg",
        "price": 65,
        "category": "Accessories",
        "quantity": 24,
        "inventoryStatus": "INSTOCK",
              "rating": 5,
              "orders": [
                  {
                      "id": "1000",
                      "productCode": "f230fh0g3",
                      "date": "2020-09-13",
                      "amount": 65,
                      "quantity": 1,
                      "customer": "David James",
                      "status": "PENDING"
                  },
                  {
                      "id": "1001",
                      "productCode": "f230fh0g3",
                      "date": "2020-05-14",
                      "amount": 130,
                      "quantity": 2,
                      "customer": "Leon Rodrigues",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "1002",
                      "productCode": "f230fh0g3",
                      "date": "2019-01-04",
                      "amount": 65,
                      "quantity": 1,
                      "customer": "Juan Alejandro",
                      "status": "RETURNED"
                  },
                  {
                      "id": "1003",
                      "productCode": "f230fh0g3",
                      "date": "2020-09-13",
                      "amount": 195,
                      "quantity": 3,
                      "customer": "Claire Morrow",
                      "status": "CANCELLED"
                  }
              ]
      },
      {
        "id": "1001",
        "code": "nvklal433",
        "name": "Black Watch",
        "description": "Product Description",
        "image": "black-watch.jpg",
        "price": 72,
        "category": "Accessories",
        "quantity": 61,
        "inventoryStatus": "INSTOCK",
              "rating": 4,
              "orders": [
                  {
                      "id": "2000",
                      "productCode": "nvklal433",
                      "date": "2020-05-14",
                      "amount": 72,
                      "quantity": 1,
                      "customer": "Maisha Jefferson",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "2001",
                      "productCode": "nvklal433",
                      "date": "2020-02-28",
                      "amount": 144,
                      "quantity": 2,
                      "customer": "Octavia Murillo",
                      "status": "PENDING"
                  }
              ]
      },
      {
        "id": "1002",
        "code": "zz21cz3c1",
        "name": "Blue Band",
        "description": "Product Description",
        "image": "blue-band.jpg",
        "price": 79,
        "category": "Fitness",
        "quantity": 2,
        "inventoryStatus": "LOWSTOCK",
              "rating": 3,
              "orders": [
                  {
                      "id": "3000",
                      "productCode": "zz21cz3c1",
                      "date": "2020-07-05",
                      "amount": 79,
                      "quantity": 1,
                      "customer": "Stacey Leja",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "3001",
                      "productCode": "zz21cz3c1",
                      "date": "2020-02-06",
                      "amount": 79,
                      "quantity": 1,
                      "customer": "Ashley Wickens",
                      "status": "DELIVERED"
                  }
              ]
      },
      {
        "id": "1003",
        "code": "244wgerg2",
        "name": "Blue T-Shirt",
        "description": "Product Description",
        "image": "blue-t-shirt.jpg",
        "price": 29,
        "category": "Clothing",
        "quantity": 25,
        "inventoryStatus": "INSTOCK",
              "rating": 5,
              "orders": []
      },
      {
        "id": "1004",
        "code": "h456wer53",
        "name": "Bracelet",
        "description": "Product Description",
        "image": "bracelet.jpg",
        "price": 15,
        "category": "Accessories",
        "quantity": 73,
        "inventoryStatus": "INSTOCK",
              "rating": 4,
              "orders": [
                  {
                      "id": "5000",
                      "productCode": "h456wer53",
                      "date": "2020-09-05",
                      "amount": 60,
                      "quantity": 4,
                      "customer": "Mayumi Misaki",
                      "status": "PENDING"
                  },
                  {
                      "id": "5001",
                      "productCode": "h456wer53",
                      "date": "2019-04-16",
                      "amount": 2,
                      "quantity": 30,
                      "customer": "Francesco Salvatore",
                      "status": "DELIVERED"
                  }
              ]
      },
      {
        "id": "1005",
        "code": "av2231fwg",
        "name": "Brown Purse",
        "description": "Product Description",
        "image": "brown-purse.jpg",
        "price": 120,
        "category": "Accessories",
        "quantity": 0,
        "inventoryStatus": "OUTOFSTOCK",
              "rating": 4,
              "orders": [
                  {
                      "id": "6000",
                      "productCode": "av2231fwg",
                      "date": "2020-01-25",
                      "amount": 120,
                      "quantity": 1,
                      "customer": "Isabel Sinclair",
                      "status": "RETURNED"
                  },
                  {
                      "id": "6001",
                      "productCode": "av2231fwg",
                      "date": "2019-03-12",
                      "amount": 240,
                      "quantity": 2,
                      "customer": "Lionel Clifford",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "6002",
                      "productCode": "av2231fwg",
                      "date": "2019-05-05",
                      "amount": 120,
                      "quantity": 1,
                      "customer": "Cody Chavez",
                      "status": "DELIVERED"
                  }
              ]
      },
      {
        "id": "1006",
        "code": "bib36pfvm",
        "name": "Chakra Bracelet",
        "description": "Product Description",
        "image": "chakra-bracelet.jpg",
        "price": 32,
        "category": "Accessories",
        "quantity": 5,
        "inventoryStatus": "LOWSTOCK",
              "rating": 3,
              "orders": [
                  {
                      "id": "7000",
                      "productCode": "bib36pfvm",
                      "date": "2020-02-24",
                      "amount": 32,
                      "quantity": 1,
                      "customer": "Arvin Darci",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "7001",
                      "productCode": "bib36pfvm",
                      "date": "2020-01-14",
                      "amount": 64,
                      "quantity": 2,
                      "customer": "Izzy Jones",
                      "status": "PENDING"
                  }
              ]
      },
      {
        "id": "1007",
        "code": "mbvjkgip5",
        "name": "Galaxy Earrings",
        "description": "Product Description",
        "image": "galaxy-earrings.jpg",
        "price": 34,
        "category": "Accessories",
        "quantity": 23,
        "inventoryStatus": "INSTOCK",
              "rating": 5,
              "orders": [
                  {
                      "id": "8000",
                      "productCode": "mbvjkgip5",
                      "date": "2020-06-19",
                      "amount": 34,
                      "quantity": 1,
                      "customer": "Jennifer Smith",
                      "status": "DELIVERED"
                  }
              ]
      },
      {
        "id": "1008",
        "code": "vbb124btr",
        "name": "Game Controller",
        "description": "Product Description",
        "image": "game-controller.jpg",
        "price": 99,
        "category": "Electronics",
        "quantity": 2,
        "inventoryStatus": "LOWSTOCK",
              "rating": 4,
              "orders": [
                  {
                      "id": "9000",
                      "productCode": "vbb124btr",
                      "date": "2020-01-05",
                      "amount": 99,
                      "quantity": 1,
                      "customer": "Jeanfrancois David",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "9001",
                      "productCode": "vbb124btr",
                      "date": "2020-01-19",
                      "amount": 198,
                      "quantity": 2,
                      "customer": "Ivar Greenwood",
                      "status": "RETURNED"
                  }
              ]
      },
      {
        "id": "1009",
        "code": "cm230f032",
        "name": "Gaming Set",
        "description": "Product Description",
        "image": "gaming-set.jpg",
        "price": 299,
        "category": "Electronics",
        "quantity": 63,
        "inventoryStatus": "INSTOCK",
              "rating": 3,
              "orders": [
                  {
                      "id": "10000",
                      "productCode": "cm230f032",
                      "date": "2020-06-24",
                      "amount": 299,
                      "quantity": 1,
                      "customer": "Kadeem Mujtaba",
                      "status": "PENDING"
                  },
                  {
                      "id": "10001",
                      "productCode": "cm230f032",
                      "date": "2020-05-11",
                      "amount": 299,
                      "quantity": 1,
                      "customer": "Ashley Wickens",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "10002",
                      "productCode": "cm230f032",
                      "date": "2019-02-07",
                      "amount": 299,
                      "quantity": 1,
                      "customer": "Julie Johnson",
                      "status": "DELIVERED"
                  },
                  {
                      "id": "10003",
                      "productCode": "cm230f032",
                      "date": "2020-04-26",
                      "amount": 299,
                      "quantity": 1,
                      "customer": "Tony Costa",
                      "status": "CANCELLED"
                  }
              ]
      }
    ]

  // @Input() ticketContext;

  public filtered:                            boolean = false;
  public isAlrdyFiltered:                     boolean = false;

  public sorted:                              boolean = false;
  public sortedAsc:                           boolean = true;

  public contextMenuMultiEnabled                      = false;
  public selectionMode                                = "single";
  public checkBoxesEnabled:                   boolean = false;

  public columnName;
  public operator;
  public refreshState:                       boolean = false;
  public dataKey:                            string = "PKValue";
  @Input() rowIsReorderable:                      boolean = false;

  // public CustomColumnVisible:   boolean = false;
  // public CustomColumnImg:       string  = null;


  @Input() noSelectionOnSort:                 boolean = false;


  @Input()
  set multiSelectEnabled(multi) {
    this.selectionMode = multi ? "multiple" : "single";
    this.checkBoxesEnabled = multi ? true : false;
    this.contextMenuMultiEnabled = true;
  }

  @Input() tableBorder:                       boolean = true;

  set resizableColumnsEnabled(resizable) {
    this.resizableColumns = resizable ? true : false;
  }
  set reorderableColumnsEnabled(reorderable) {
    this.reorderableColumns = reorderable ? true : false;
  }

  public get PKValue():                       string { return this.FPKValue; } // get PKValue


  // [(contextMenuSelection)]="selectedRecord" das hier muss in das p-table Element in der HTML eingebaut werden,
  // damit man mit der unten angegebenen Funktion selectRecord(a_Value) Datensätze selecten kann, wenn man das Kontextmenü mit Rechtsklick auslöst
  // public set selectedRecord(a_Value) {
    // this.dataSelected = a_Value;
    // let args: TvsTableComponentEventArgs = new TvsTableComponentEventArgs(a_Value, a_Value.PKValue);
    // this.onRowSelect.emit(args);
    // console.log('TEST1: ', a_Value);
    // console.log('TEST2: ', this.DataSelected);
  // }

  //HEIGHT Wert der Tabellenhöhe kommt von der übergeordneten view per Property
  public calculatedTableHeight: string;
  //HEIGHT

  @Input()
  set setTableHeight(height) {
    this.calculatedTableHeight = height;
  }

  //tableBorder - Eine Property um die Border der Tabelle an und auszustellen
  public get  tableCss() {

    if (!this.tableBorder) {
      return 'vsViewSectionMainTable vsBorderOff';
    }

    else {
      return 'vsViewSectionMainTable';
    }
  } // tableCss


  //---- Events
  @Output() onRowReorder: EventEmitter<any> = new EventEmitter<TvsTableComponentEventArgs>();
  @Output() onRowSelect: EventEmitter<TvsTableComponentEventArgs> = new EventEmitter<TvsTableComponentEventArgs>();
  @Output() onDrop: EventEmitter<TvsTableComponentEventArgs> = new EventEmitter<TvsTableComponentEventArgs>();
  @Output() onCustomButtonClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() onActionEditClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActionDeleteClick: EventEmitter<any> = new EventEmitter<any>();


  //====== ViewChilds

  @ViewChild('tableMainInternal') tableMainInternal: any;

  //====== Constructor

  constructor(public        globalResources?:      vsGlobalResources.TvsGlobalResources) {

  } // constructor



  //====== Property-Zugriffsmethoden

  // ./.



  //====== Methods: NG event handler

  ngOnInit(): void {
    //
    // MultiSortMeta für die PrimeNG-Table erzeugen, um die anfängliche Sortierung gemäß
    // ViewConfig einzustellen.
    //
    this.SortDefs = [];
    this.DOMain.SortDefs.forEach(element => {
      if (element.ColumnSort != 0) {
        this.SortDefs.push({ field: element.FieldName, order: element.SortDirection })
      }
    });

    this.DOMain.Dataset;
    // console.log(this.DOMain.Dataset)


  } // ngOnInit

  ngAfterViewInit() {

  }


  //====== Methods: Event Handler

  doOnDrop = (event): any => {

      this.onRowReorder.emit();

  };


  public doOnRowClick(record: any): void {
    console.log('>> TvsTableComponent.doOnRowClick(): record = ', record);


  } // doOnRowClick


  doSelect(a_Data?: any, a_PKValue?: any): void {
    let args: TvsTableComponentEventArgs;
    if (this.DOMain.Dataset.Data.length === 0) {
        this.dataSelected = [];
        args = new TvsTableComponentEventArgs(null, null);
        this.filtered = false;
    } else {
      if (vsVarAssigend(a_Data)) {
        // console.log('EDIT - Data', a_Data);
        // console.log('EDIT - Data', a_PKValue);
        args = new TvsTableComponentEventArgs(a_Data, a_PKValue);
      } else {
        args = new TvsTableComponentEventArgs(this.DOMain.Dataset.Data[0], this.DOMain.Dataset.Data[0].PKValue);
      }
      //  console.log('DoSelect2: ', args);
    }

    //Aktualisierungsbedingung für single-select. Auswahl wird auf den ersten Datensatz geschoben.
    if(this.selectionMode !== "multiple" && this.dataSelected) {
        this.dataSelected = this.DOMain.Dataset.Data[0];
        args = new TvsTableComponentEventArgs(this.DOMain.Dataset.Data[0], this.DOMain.Dataset.Data[0].PKValue);
    }

    //Startbedingung für multi-select. Keine Auswahl.
    if((!this.refreshState && this.selectionMode === 'multiple')) {
        this.dataSelected = [];
        args = new TvsTableComponentEventArgs(null, null);
        this.refreshState = true;
    } else {
        if(this.selectionMode !== "multiple" || this.dataSelected.length > 0) {
          this.onRowSelect.emit(args);
        }
    }
}



  // doOnStateRestore(event:any) {
  //   console.log('doOnStateRestore');
  // }



  // //Diese Funktion muss noch von wvViewDataList übergeben werden statts hier renundanten Code zu verwenden!!
  // doActionTicketOpen(record) {
  //   let args: TvsTableComponentEventArgs;
  //   args = new TvsTableComponentEventArgs(record, record.PKValue);
  //   this.onRowSelect.emit(args);
  //   // console.log(this.context.dataSelected);
  //   this.dataSelected = this.ticketContext.dataSelected;

  //   this.tableMainInternal._selection = this.dataSelected;
  //   // console.log("A_CALLER", a_caller);
  //   this.ticketContext.ParamList.add('TabTitle', 'Ticket [' + this.ticketContext.dataSelected.Vorf_Nr + ']');

  //   this.ticketContext.globalResources.ViewMain.dispatchProgFunc(AMIS7coreConst.gc_sProgFuncID_CRM_Ticket,
  //     vsCommon.TvsAppViewType.avtEdit,
  //     this.ticketContext.dataSelected.WV_ID_Vorfall,
  //     this.ticketContext.ParamList);
  // }


  customSort(event: any) {
    let search = this.DOMain.Dataset.FieldDefs.map(el => el.Name);
    let i = search.indexOf(event.field);

    this.DOMain.Dataset.FieldDefs[i].Type == 'ftDate';
    let bDate = this.DOMain.Dataset.FieldDefs[i].Type == 'ftDate';
    let value1: any;
    let value2: any;

    event.data.sort((data1, data2) => {

      switch (this.DOMain.Dataset.FieldDefs[i].Type) {
        // case 'ftDate': {
        //   value1 = vsCommon.vsDateToVsDate(data1[event.field]);
        //   value2 = vsCommon.vsDateToVsDate(data2[event.field]);
        //   break;
        // }
        case 'ftInteger': {
          value1 = vsCommon.vsStrPadLeft(data1[event.field], '0', 10);
          value2 = vsCommon.vsStrPadLeft(data2[event.field], '0', 10);
          // console.log('v1:', data1[event.field] + ' / ' + value1);
          // console.log('v2:', data2[event.field] + ' / ' + value2);
          break;
        }
        default: {
          value1 = data1[event.field];
          value2 = data2[event.field];
        }
      }
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      // console.log('Erg:', result);
      // console.log('Order:', event.order);
      // console.log('Ret:', (event.order * result));


      return (event.order * result);
    });

      if(this.selectionMode === "multiple" &&  this.DOMain.Dataset.Data.length > 1 && !this.noSelectionOnSort) {
        this.dataSelected = [];
        this.tableMainInternal._selection = [];
      }
      if (this.selectionMode === "single" && !this.noSelectionOnSort) {

        this.dataSelected = [this.tableMainInternal._value[0]];

        this.tableMainInternal._selection = this.dataSelected;
        this.onRowSelect.emit(new TvsTableComponentEventArgs(this.dataSelected[0], this.dataSelected[0].PKValue));

      }
      if(this.selectionMode === "single" && this.noSelectionOnSort) {
        this.dataSelected = this.DOMain.Dataset.Data[0];

        this.tableMainInternal._selection = this.dataSelected[0];
        this.onRowSelect.emit(new TvsTableComponentEventArgs(this.dataSelected[0], this.dataSelected[0].PKValue));
      }


  }

  doRetrieveValue(a_sColType: string, a_sColValue: string): string {
    // console.log("vsTable", this.DOMain.Dataset);
    let sValue = a_sColValue;

    if (a_sColValue !== '' && vsCommon.vsVarAssigend(a_sColValue)) {
      if (a_sColType === "ftDate") {
        sValue = a_sColValue.substring(8, 10) + "." + a_sColValue.substring(5, 7) + "." + a_sColValue.substring(0, 4);
      }
      else if (a_sColType === "ftTime") {
        sValue = a_sColValue.substring(11, 16);
      }
      else if (a_sColType === "ftDateTime") {
        // console.log('a_sColValue: ', a_sColValue)
        sValue = a_sColValue.substring(8, 10) + "." + a_sColValue.substring(5, 7) + "." + a_sColValue.substring(0, 4) + " " + a_sColValue.substring(11, 16);
      }
      else if (a_sColType === "ftFloat") {
        // erst Komma zu Punkt ändern, damit es als Float-Komma erkannt wird
        sValue = sValue.replace(',', '.').toString();
        // Formatieren und Punkt wieder zu Komma ändern
        sValue = parseFloat(sValue).toFixed(2).replace('.',',');
      }
      else if (a_sColType === "ftCurrency") {
        // erst Komma zu Punkt ändern, damit es als Float-Komma erkannt wird
        sValue = sValue.replace(',', '.').toString();
        // Formatieren und Punkt wieder zu Komma ändern
        sValue = parseFloat(sValue).toFixed(2).replace('.',',') + ' €';
      }
    }

    return sValue;

  }

  // eventEmitDoubleClick(event:any){
  //   alert('Doubleclick');
  // }


  // Wird nur bei manueller Auswahl ausgeführt
  doOnRowSelect(event: any) {
    let bNewSelected: boolean = true;
    console.log('>> TvsTableComponent.doOnRowSelect(): event.data        = ', this.dataSelected);
    // if (bNewSelected) { // obsolet?? weil sowieso immer true
    bNewSelected = (this.FPKValue != event.data['PKValue']);
    // } //obsolet?? weil sowieso immer true
    // Auswahl bei single select
    if(this.selectionMode === "single") {
      // this.onRowSelect.emit(new TvsTableComponentEventArgs(this.dataSelected, this.dataSelected.PKValue));
      this.onRowSelect.emit(new TvsTableComponentEventArgs(event.data, event.data['PKValue']));
    }
    // Bisher ist die Bearbeitung oder das Löschen nur eines Datensatzes erlaubt
    else if(this.selectionMode === "multiple" && this.dataSelected.length === 1) {
      // this.onRowSelect.emit(new TvsTableComponentEventArgs(this.dataSelected[0], this.dataSelected[0].PKValue));
      this.onRowSelect.emit(new TvsTableComponentEventArgs(event.data, event.data['PKValue']));
    }

    // &Rev 2023-03-07  MH   BugFix: DoOnRowSelect emmitted mehrere Events bei einem Aufruf
    // Scheinbar obsolet - deswegen deaktiviert
    // Vorher wurde dieser Fall zusätzlich aufgerufen, wenn SelectionMode = Single ist.

    // if (this.selectionMode !== "multiple" && (bNewSelected || (!this.KennEmitOnlyWhenNewRecordSelected))) {
    //   this.FPKValue = event.data['PKValue'];
    //   this.onRowSelect.emit(new TvsTableComponentEventArgs(event.data, this.FPKValue));
    // }
  } // doOnRowSelect

  // Bei single select unSelect verhindern, bei multiple unSelect zulassen
  doOnRowUnselect(event: any) {
    if (this.selectionMode === "single") {
      this.FPKValue = event.data['PKValue'];
      this.tableMainInternal.selection = event.data;
      this.tableMainInternal.selectionChange.emit(event.data);
      this.tableMainInternal.updateSelectionKeys();
      this.onRowSelect.emit(new TvsTableComponentEventArgs(event.data, this.FPKValue));
    }
    // Bisher ist die Bearbeitung oder das Löschen nur eines Datensatzes erlaubt
    if (this.selectionMode === "multiple" && this.dataSelected.length === 1) {
      this.onRowSelect.emit(new TvsTableComponentEventArgs(this.dataSelected[0], this.dataSelected[0].PKValue));
    }

  } // doOnRowUnselect

  doContextMenuSelect(event: any) {
    // console.log('Context-Menu-Select: ', event)
    this.doOnRowSelect(event);
  }

  //$REV MP 2023-06-23: Name zum doCustomButtonClick hinzugefügt, damit man per Name in den Fielddefs mehrere Events unterscheiden kann.
  doCustomActionButtonClick(event: any, name: string) {
    // console.log('doCustomAction: ', event);
    this.onCustomButtonClick.emit({event: event, name: name});
  }

  doActionEditClick(event: any) {
    // console.log('doCustomAction: ', event);
    this.onActionEditClick.emit(event);
  }

  doActionDeleteClick(event: any) {
    // console.log('doCustomAction: ', event);
    this.onActionDeleteClick.emit(event);
  }

} // TvsTableComponent


