//------------------------------------------------------------------------------
//  vedisys Library
//
//  File:   vsViewDataEdit.component.ts
//  Notes:  View component: Basisklasse für Edit Views
//
//
//  Klassenhierarchie:
//
//  TvsView
//   |
//   +-- TvsViewData
//     |
//     +-- TvsViewDataList
//     |
//     +-- TvsViewDataEdit
//
//
//  H I S T O R Y
//
//  2021-06-20  TC   Initial Version
//------------------------------------------------------------------------------

import {  Component, Input
        , OnInit, AfterViewInit
        , EventEmitter
        , Directive
        , ViewContainerRef, ViewChild, ContentChild
        , TemplateRef,

}                                     from '@angular/core';

import { Observable, Subject }                    from 'rxjs';
import { FormGroup }                  from '@angular/forms';
import { FormControl }                from '@angular/forms';
import { Router }                     from '@angular/router';

// [ngx-translate]
import { TranslateService }           from '@ngx-translate/core';   // TODO: Verlagern nach TvsView

// vsLib
import * as vsCommon                        from '@vsLib/vsCommon';
import * as vsGlobalResources         from '@vsLib/Services/vsGlobalResources.service';
import * as vsViewData                from '@vsLib/View/components/vsViewData/vsViewData.component';
import { TvsHTTPMethod, TvsHttpServiceComponent, TvsGetType }    from '@vsLib/Services/vsHttp.service.component';
// import { TvsViewUsageMode }           from '@vslib/View/vsView.component';


// PrimeNG
import { DialogService, DynamicDialogRef,
         DynamicDialogConfig }              from 'primeng/dynamicdialog';

//$REV MP 2022-03-25 Aktion auskommentiert da es zu kompilierungsproblemen in AMIS7bm und im AMIS7bm_Portal_Knd kam


//
// TvsViewEditMode
//
// Enumeration:
// Specifies the mode of a edit view. Based on this information, properties will be set to
// configure the provided actions and behaviour of the view.
//
export enum TvsViewEditMode {
  vemInsert               // Entering data of a new record
                          // The view will be opened with empty input fields, possibly some fields
                          // will be filled with default data.

, vemEdit                 // Editing an existing record
                          // The view will be opened with the specified record loaded and its fields
                          // displayed. The field values can be editied.

, vemReadOnly             // Viewing an existing record
                          // The view will be opened with the specified record loaded and its fields
                          // displayed. The field values can NOT be edited.
} // TvsViewEditMode



//
// TvsViewDataEdit
//
// Base class: Edit View
//
@Component({
  selector:       'vsViewDataEdit',
  templateUrl:  './vsViewDataEdit.component.html',
  styleUrls:   ['./vsViewDataEdit.component.scss'],
  providers: [DialogService]
})
export class TvsViewDataEdit extends vsViewData.TvsViewData implements OnInit, AfterViewInit {


  //====== Properties (PRIVATE)

  // private     FPKValue:                     string                  = null;      // $Rev TC 2021-06-06: verlagert nach TvsViewData
  private     FViewEditMode:                TvsViewEditMode         = null;
  private     FIsModified:                  boolean                 = false;

  @Input()    TemplateSectionDataMain:      TemplateRef<any>        = null;         // $Rev MHk 2021-06-01: added
  @Input()    TemplateSectionDataDetail:    TemplateRef<any>        = null;         // $Rev MHk 2021-06-01: added

  public      editForm:                     FormGroup               = new FormGroup({});
  // public      IsModified:                   boolean                 = false;

  public get IsModified(): boolean {return this.FIsModified}
  public set IsModified(a_bValue) {
            this.FIsModified = a_bValue;
            this.tbElemByName('tbSave').Enabled      = this.IsModified && (this.ViewEditMode != TvsViewEditMode.vemReadOnly);
            this.tbElemByName('tbOK').Enabled        = this.IsModified && (this.ViewEditMode != TvsViewEditMode.vemReadOnly);
        }



  //====== Properties (PROTECTED)

  // ./.

  //====== Properties (PUBLIC)

  // public  get PKValue():                    string                  {return this.FPKValue}       // $Rev TC 2021-06-06: verlagert nach TvsViewData
  // public  set PKValue(a_sValue: string)                             {this.FPKValue = a_sValue}   // $Rev TC 2021-06-06: verlagert nach TvsViewData

  public  get ViewEditMode():               TvsViewEditMode         {return this.FViewEditMode}
  //public  get Modified1():                   boolean                 {return this.Modified}

  public DateEditOnSaveEvent:               Subject<any>            = new Subject();     // $Rev MHk 2021-06-01: added

  public displayDialogCancel:               boolean                 = false;
  public displayDialogError:                boolean                 = false;

  // MP Insert Detailbereich ausblenden
  // Wert kann initial auf false gesetzt werden, wenn if-Anragen weiter unten im Code funktionieren
  // Wertänderung im ngAfterViewInit funktioniert

  public kennDetailSectionVisible:                boolean                 = true;



  // tslint:disable-next-line: adjacent-overload-signatures
  public  set ViewEditMode(a_vem: TvsViewEditMode) {
    this.FViewEditMode  = a_vem;

    (this.ViewProperties.InstanceBase as TvsViewDataEdit).kennDetailSectionVisible = (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode !== 0;



    // $Rev TC 2021-03-21 - TODO:
    // Entweder werden an dieser Stelle bereits weitere Aktionen ausgeführt, um die View
    // gemäß angegebenem Modus einzustellen, oder dies geschieht an anderer Stelle - z.B.
    // innerhalb einer Methode Init() oder so, die an einer besser geeigneten Stelle auf-
    // gerufen wird.

    // Zu den auszuführenden Aktionen gehören:
    // # Aktivieren/Deaktivieren von Actions (jeweils bestehend aus Toolbar Item & zugehöriger action-Methode)
    // # Eingabefelder initialisieren:
    //   -- mit eingelesenen Daten (vemEdit, vemReadOnly)
    //   -- leer, ggf. mit Default-Werten (vemInsert)
    // # ...
    //

  } // set ViewEditMode

  // public get ShowDetails(): boolean { return (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode > 0; }


  //---- Events

  public  OnDataEditSave:     EventEmitter<any>       = new EventEmitter<any>();


  public refOpen: DynamicDialogRef;


  //====== Constructor

  constructor(protected router:               Router
            , protected globalResources:      vsGlobalResources.TvsGlobalResources
            , protected HttpServiceComponent: TvsHttpServiceComponent
            , public    ref:                  DynamicDialogRef
            , public    config:               DynamicDialogConfig
            , public    dialogService:        DialogService


  ) {
    super(router, globalResources, HttpServiceComponent);

    this.ViewProperties.Instance      = this;
    this.ViewProperties.ViewTitle     = this.constructor.name;

    //  if (this.constructor.name === 'TvsViewDataEdit') {
    //     this.ViewProperties.InstanceBase = this;
    //  }
    //  else {
    //   }
      this.ViewProperties.InstanceBase = this;
      // this.ViewProperties.InstanceNameBase = this.constructor.name;


 console.log('ng_const this ', this);
// console.log('ngTest ', this.ViewProperties.Instance);
    // Dummy: Damit kann man ein Edit-Form manuell auf Modified setzen und die Aktionen auslösen (Speciher-/OK-Button enabled)
    this.editForm.addControl('Modified',    new FormControl(null));
    this.avt  = vsCommon.TvsAppViewType.avtEdit;

 } // constructor


  //====== Methods: NG event handler


  ngOnInit(): void {
    console.log('*** ngOnInit');

    // ====== Toolbar Items

    // [LABOR] ---------- BEG ----------

    this.ViewProperties.ToolbarItems  = [
        {ID: "tbSave",                    ID_Parent: null,          Caption: "Speichern",               Separator: false,   Enabled: true, Visible: true, tbLeft: true, src: '/assets/img/Icons/vs-save-circle.svg'}
      , {ID: "tbRefresh",                 ID_Parent: null,          Caption: "Neu laden",               Separator: false,   Enabled: true, Visible: true, tbLeft: true, src: '/assets/img/Icons/vs-refresh-circle.svg'}
      // , {ID: null,                        ID_Parent: null,          Caption: null,                     Separator: true,   Enabled: true}
      , {ID: "tbActions",                 ID_Parent: null,          Caption: "Aktionen",                Separator: false,   Enabled: true, Visible: false, tbLeft: true, src: '/assets/img/Icons/vs-action-circle.svg'}
      , {ID: "tbReports",                 ID_Parent: null,          Caption: "Berichte",                Separator: false,   Enabled: true, Visible: false, tbLeft: true, src: '/assets/img/Icons/vs-report-circle.svg'}

      // , {ID: "tbActionsActivateOrder",    ID_Parent: "tbActions",   Caption: "Bestellung: Aktivieren"}
      // , {ID: "tbReportsOrderOverview",    ID_Parent: "tbReports",   Caption: "Bestellungen: Übersicht"}

      , {ID: "tbOK",                      ID_Parent: null,          Caption: "Speichern & Schließen",   Separator: false,   Enabled: false, Visible: true, tbLeft: false, src: '/assets/img/Icons/vs-check-circle.svg'}
      , {ID: "tbAbbruch",                 ID_Parent: null,          Caption: "Abbruch",                 Separator: false,   Enabled: true,  Visible: true, tbLeft: false, src: '/assets/img/Icons/vs-close-circle.svg'}
    ];

    // console.log('ngOnInit_this', this);
    (this.ViewProperties.Instance as TvsViewDataEdit).ViewProperties.InstanceBase = this;
    // console.log('ngOnInit_Viewprop', (this.ViewProperties.Instance as TvsViewDataEdit).ViewProperties);


    // [LABOR] ---------- END ----------
  // this.LoadContextMenu();

  } // ngOnInit

  // protected LoadContextMenu():  void {
  //   (this.ViewProperties.Instance as vsView.TvsView).ViewProperties.MenuItemsContext = [];

  //   (this.ViewProperties.Instance as vsView.TvsView).ViewProperties.MenuItemsContext.push(
  //       { label: 'Aktionen', items: (this.ViewProperties.Instance as TvsViewDataEdit).MenuItemsAction }  );
  // }


  ngAfterViewInit(): void {
    console.log('*** ngAfterViewInit');

    // console.log('ViewEditMode.Init: ', (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode);
    // this.kennDetailSectionVisible = (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode != TvsViewEditMode.vemInsert;
    // console.log('ViewEditMode.Init.Kenn: ', this.kennDetailSectionVisible);

    (this.ViewProperties.Instance as TvsViewDataEdit).createChildViews();
    this.DialogCall();


    // Ausschalten der Detailanzeige, wenn EditMode = Insert
    (this.ViewProperties.InstanceBase as TvsViewDataEdit).kennDetailSectionVisible = (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode !== 0;

  } // ngAfterViewInit


  protected DialogCall(): void {

     //Wird scheinbar nie ausgeführt
    if (this.config.data && this.config.data.mode === 'Insert') {
      this.actionNew();
    }

    //Wird scheinbar nie ausgeführt
    else if (vsCommon.vsVarAssigend(this.config.data)) {
      this.actionEdit(this.config.data.PKValue ? this.config.data.PKValue : this.config.data);

    }
  }

  protected createChildViews(): void {
  } // createChildViews

  // protected detailDataSynchronize(): void {
  // } // detailDataSynchronize

  //====== Methods: Actions

  protected DoBeforeClose(a_Data: any): void {
    (this.ViewProperties.Instance as TvsViewDataEdit).ref.close(a_Data);
  }

  //
  // IMPORTANT:
  // All action methods are template methods and should therefore NEVER be overwritten!
  //


  //------------------------------------------------------------------------------------------------
  // Method:  actionCancel
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Cancel -> discard data and close view
  //          Template method
  //------------------------------------------------------------------------------------------------

  protected actionCancel(): void {

    if ((this.ViewProperties.Instance as TvsViewDataEdit).IsModified) {
      this.displayDialogCancel = true;
    }
    else {
      this.ViewProperties.SubjDisplay.unsubscribe();
      this.actionClose(null);
    }

  } // actionCancel


  //------------------------------------------------------------------------------------------------
  // Method:  actionEdit
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Edit -> Assigns ViewEditMode and reads data record
  //          Template method
  //------------------------------------------------------------------------------------------------

  // public actionEdit(a_sPKValue: string, a_vem: TvsViewEditMode): void {
  public actionEdit(a_sPKValue: string): void {
    this.ViewEditMode  = TvsViewEditMode.vemEdit;
    (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode  = TvsViewEditMode.vemEdit;

    this.PKValue        = a_sPKValue;

    this.actionRefresh(TvsGetType.gtNormal, this.PKValue);
  } // actionEdit

//------------------------------------------------------------------------------------------------
  // Method:  actionNew
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: New
  //          Template method
  //------------------------------------------------------------------------------------------------

  public actionNew(): void {
    this.ViewEditMode  = TvsViewEditMode.vemInsert;
    (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode  = TvsViewEditMode.vemInsert;

    this.actionRefresh(TvsGetType.gtNewRecord);

  } // actionNew


  //------------------------------------------------------------------------------------------------
  // Method:  actionOK
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: OK -> save data and close view
  //          Template method
  //------------------------------------------------------------------------------------------------

  //protected actionOK(): void {
  async ActionOK(): Promise<void> {
     //alert('>>>> ' + this.constructor.name + ':  Action: OK');
     console.log('ActionOK',  (this.ViewProperties.Instance as TvsViewDataEdit).IsModified);

    //this.actionSave(true, true);  // Schließt auch die EditView

    try {
      await this.actionSave(true, true);; // Warten auf den Abschluss von ActionSave
      console.log('ActionSave wurde erfolgreich abgeschlossen.');
      // Optional: Weiterverarbeitung, falls nötig
    } catch (error) {
      console.error('Fehler in ActionSave:', error);
    }

  } // actionOK


  //------------------------------------------------------------------------------------------------
  // Method:  actionSave
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Action: Save data (keeps view open and visible, possibly for further actions)
  //          Template method
  //------------------------------------------------------------------------------------------------
  //$REV MP: 2023-03-13: boolean als Rückgabewert eingebaut
  //protected actionSave(_bOK: boolean, a_bClose: boolean = false): boolean {
    async actionSave(_bOK: boolean, a_bClose: boolean = false): Promise<void> {

    let bOK: boolean = true;
    // alert('>>>> ' + this.constructor.name + ':  Action: Save');
    console.log('actionSave');

    // TODO:
    //
    // # Daten speichern                          (MANTIS # 0004798  https://support.vedisys.de/view.php?id=4798)
    //   -- ViewEditMode = vemInsert  --> Insert über Service
    //   -- ViewEditMode = vemEdit    --> Update über Service
    //
    // # Alle Observer-ListViews benachrichtigen  (MANTIS # 0004578  https://support.vedisys.de/view.php?id=4578)
    //
  //======[ Gültigkeitsprüfungen ]==============================================

  // Wurden Eingaben vorgenommen?
  if (bOK) {
    bOK = (this.ViewProperties.Instance as TvsViewDataEdit).IsModified;
  }

  // Gültigkeitsprüfung der Benutzereingaben
  if (bOK) {
    bOK = (this.ViewProperties.Instance as TvsViewDataEdit).ValidateData();

    if (!bOK) {
      this.displayDialogError = true;
    }
  }

  if (bOK) {
    bOK = await (this.ViewProperties.Instance as TvsViewDataEdit).ValidateDataPromise();
  }

  if (bOK)
  {
    // console.log('Daten werden gespeichert');
    //------ Daten speichern
    this.IsModified = false;    // Mehrfaches Speichern wird verhindert (TE)
    bOK = (this.ViewProperties.Instance as TvsViewDataEdit).dataSave();

    // console.log('this.ViewProperties.KennSaveData: ', this.ViewProperties.KennSaveData)

    if (bOK && this.ViewProperties.KennSaveData)
    {
      console.log('SAVE gestartet');

    //------ Event
// console.log('ViewEditMode: ', (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode);

      if ( (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode ==  TvsViewEditMode.vemInsert ) {    // vemInsert

        console.log("inserted", (this.ViewProperties.DOMain));

        // ... hier wird der Service für Insert  aufgerufen ...

        this.HttpServiceComponent.dataUpdate(this.ViewProperties.DataSourceID,
                                             this.ViewProperties.DOMain,
                                             null)
          .subscribe(
          dataPost => {
            this.ViewProperties.DOMain.Dataset.Data[0].PKValue = dataPost.PKValue;

            // Aktuell kommt in der Antwort kein neuer PKValue, den man für die Vervollständigung der Liste benötigt.
            // Daher wird keine Datenmenge an die ViewDataList zurückgemeldet und diese macht einfach ein Refresh der gesamten Datenmenge

            // this.ResponseHandler(TvsHTTPMethod.hmPut, dataPost, a_bClose);

            // Daten werden erneut geladen, um auch an die eventuell veränderten Refrenzwerte zu kommen um damit die Listenwerte zu aktualisieren

            this.DataFilter.ItemsClear();
            let item = this.DataFilter.ItemAdd('Filter PKValue');
            item.CriteriaAdd('PKValue', dataPost.PKValue);

            console.log('dataPost: ', dataPost);


            this.HttpServiceComponent.dataGet( this.ViewProperties.DataSourceID
                                               , this.ViewProperties.DOMain.KennFieldDefs
                                               ,[]
                                               ,this.DataFilter)
              .subscribe(
                data => {
                  this.DataFilter.ItemsClear();

                  // console.log('Put-PKValue: ', data.PKValue);
                  // console.log('Load after Insert: ', data);


                  (this.ViewProperties.Instance as TvsViewDataEdit).afterDataSave(data);
                  (this.ViewProperties.Instance as TvsViewDataEdit).ResponseHandler(TvsHTTPMethod.hmPut, data, a_bClose);
                  (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = false;
                  this.globalResources.ViewMain.msgShowToastSuccess('Neuer Datensatz wurde gespeichert');

                  (this.ViewProperties.Instance as TvsViewDataEdit).actionEdit(dataPost.PKValue);

                },
                error => {
                  console.log('Error Get-Request: ', error);
                  alert(  'Bei dem Get-Request ist ein Fehler aufgetreten:\n'
                        + error.error.Error.ErrCode + '\n'
                        + error.error.Error.ErrMsg + '\n\n'
                        + error.message );
                  this.DOMain.IsRefreshing = false;

                      },
                () => {
                  console.log('Get Request completed');
                }

             ); // dataGet
             (this.ViewProperties.Instance).ViewProperties.detailDataId = dataPost.PKValue;
             (this.ViewProperties.Instance as TvsViewDataEdit).detailDataSynchronize();
          },
          error => {
            console.log('Error Put-Request: ', error);
            // Falls das Speichern failed, wieder Speicher Button enablen (TE)
            if(!this.IsModified) {
              this.IsModified = true;
            }
            alert(  'Bei dem Put-Request ist ein Fehler aufgetreten:\n'
                  + error.error.Error.ErrCode + '\n'
                  + error.error.Error.ErrMsg + '\n\n'
                  + error.message );
            this.DOMain.IsRefreshing = false;

            },
          () => {
            console.log('Put Request completed');
          }
      );

        // $Rev TC 2021-06-06:
        // Nachfolgender Code zum Aufruf des Data Services auskommenteirt, da hier natürlich kein "Ticket"-Service
        // verwendet werden darf. Aus dem gleichen Grund wurde der Ticket-Service als Paramter des Konstruktors
        // entfernt.

        // this.ticketService.Data_Insert(TticketModelTicket.Type,
        //                               (this.ViewProperties.Instance as TvsViewDataEdit).editObject.VORFALL_ID,
        //                               (this.ViewProperties.Instance as TvsViewDataEdit).editObject);
      } // if vemInsert


      if( (this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode ==  TvsViewEditMode.vemEdit) {  // vemEdit
        // alert("edit bzw. update" + (this.ViewProperties.Instance as TvsViewDataEdit).editObject);

        // ... hier wird der Service für Update  aufgerufen ...

        this.HttpServiceComponent.dataUpdate( this.ViewProperties.DataSourceID,
                                              this.ViewProperties.DOMain,
                                              (this.ViewProperties.Instance as TvsViewDataEdit).PKValue)
          .subscribe(
          dataPost => {
            (this.ViewProperties.Instance as TvsViewDataEdit).afterDataSave();
            // Daten werden erneut geladen, um auch an die eventuell veränderten Refrenzwerte zu kommen und damit die Listenwerte zu aktualisieren

            // console.log('dataPost: ', dataPost);

            this.DataFilter.ItemsClear();
            let item = this.DataFilter.ItemAdd('Filter PKValue');
            item.CriteriaAdd('PKValue', (this.ViewProperties.Instance as TvsViewDataEdit).PKValue);
            this.HttpServiceComponent.dataGet( this.ViewProperties.DataSourceID
                                              , this.ViewProperties.DOMain.KennFieldDefs
                                              , []
                                              // ,'&PKValue=' + (this.ViewProperties.Instance as TvsViewDataEdit).PKValue)
                                              , this.DataFilter)
                                              .subscribe(
                data => {
                  (this.ViewProperties.Instance as TvsViewDataEdit).ResponseHandler(TvsHTTPMethod.hmPost, data, a_bClose);
                  this.globalResources.ViewMain.msgShowToastSuccess('Änderungen wurden gespeichert');
                  (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = false;
                },
                error => {
                  console.log('Error Get-Request: ', error);
                  alert('Bei dem Get-Request ist ein Fehler aufgetreten:\n'
                        + error.error.Error.ErrCode + '\n'
                        + error.error.Error.ErrMsg + '\n\n'
                        + error.message );
                  this.DOMain.IsRefreshing = false;
                        },
                () => {
                  console.log('Get Request completed');
                }

            ) // dataGet
          },
          error => {
            console.log('Error Post-Request: ', error);
            // Falls das Speichern failed, wieder Speicher Button enablen (TE)
            if(!this.IsModified) {
              this.IsModified = true;
            }
            alert('Bei dem Post-Request ist ein Fehler aufgetreten:\n'
            + error.error.Error.ErrCode + '\n'
            + error.error.Error.ErrMsg + '\n\n'
            + error.message );
            this.DOMain.IsRefreshing = false;

            },
          () => {
            console.log('Post Request completed');
          }


        ); // dataPost

      } // // if vemEdit


//      this.OnDataEditSave.emit('TvsViewDataEdit.actionSave()');
      }  // if bOK
      else {
      }
    } // if bOK

    //return bOK;

  } // actionSave


  protected  InternalRefresh(a_data: any) {

    this.ViewProperties.SubjDisplay.subscribe(
      (args) => {
        // Wenn DataDisplay beendet ist, der neue Datensatz gespeichert wurde...

        // console.log('DataDisplay ist beendet!');

        (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = false;

        console.log('DataDisplay Modified = FALSE!');

        // this.tbElemByName('tbSave').Enabled     = false;
        // this.tbElemByName('tbOK').Enabled       = false;

        let bModified = (this.ViewProperties.Instance as TvsViewDataEdit).IsModified;

        // alert('Subscribe EditForm-Changes: ' + (this.ViewProperties.Instance as TvsViewDataEdit).ViewProperties.ViewTitle);
        (this.ViewProperties.Instance as TvsViewDataEdit).editForm.valueChanges.subscribe(selectedValue =>
          {
            if (!bModified) {

              (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = true;

              // console.log('Modified: ' + selectedValue);
              // console.log('MODIFIED:', bModified);

              // this.tbElemByName('tbSave').Enabled      = true;
              // this.tbElemByName('tbOK').Enabled        = true;
            }
          });

            (this.ViewProperties.Instance as TvsViewDataEdit).detailDataSynchronize();
        }
      );

    this.init();

  } // InternalRefresh


  private init(): void {

    this.doBeforeInit();

    this.dataDisplay();

    this.doAfterInit();

  }

  protected dataDisplay(): void {
  } // dataDisplay

  protected doBeforeInit(): void {
    console.log('### doBeforeInit ###');
  } // doBeforeInit

  protected doAfterInit(): void {
    console.log('### doAfterInit ###');
  } // doAfterInit



  private tbElemByName(a_sName: string): any {
    let tb   = this.ViewProperties.ToolbarItems;
    let x    = tb.map(el => el.ID)
    let i    = x.indexOf(a_sName);

    return tb[i];
  } // tbElemByName




  protected dataSave(): boolean {
    console.log('vsViewDataEdit.dataSave');
    return true;
  } // dataSave

  protected afterDataSave(data?): void {
    console.log('vsViewDataEdit.afterDataSave');
  } // dataSave


  protected ValidateData(): boolean {
    console.log('vsViewDataEdit.ValidateData');
    return true;
  }

  async    ValidateDataPromise(): Promise<boolean> {
    console.log('vsViewDataEdit.ValidateDataPromise');
    return true;
  }


  //====== Methods: Miscellanious


  //------------------------------------------------------------------------------------------------
  // Method:  btnToolbar...
  //
  // Args:    ./.
  //
  // Result:  ./.
  //
  // Notes:   Ereignisbehandlungen: Toolbar Items
  //------------------------------------------------------------------------------------------------

  // public btnToolbarOK_OnClick(): void {
  //   this.actionOK();
  // } // doMenuMainItemClick

  public btnToolbarCancel_OnClick(): void {
     this.actionCancel();
   } // doMenuMainItemClick



  //====== Methods: Hooks / Event handler


  protected doAfterRefresh() {
    super.doAfterRefresh();
    (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = false;

    // console.log('>>>> TvsViewDataEdit.doAfterRefresh()');

  } // doAfterRefresh




  private closeActiveTab(){
    // let TabControl = this.globalResources.ViewMain.tabsMain;
    // TabControl.deleteActiveItem();
    //this.actionClose();
  }

  //====== [LABOR]

  //$REV MP 2022-03-25 Aktion auskommentiert da es zu Kompilierungsproblemen in AMIS7bm und im AMIS7bm_Portal_Knd kam



  public btnToolbar_OnClick(a_iItemIdx: number): void {
    console.log('>>>> ' + this.constructor.name + '.btnToolbar_OnClick(): a_iItemIdx = ' + a_iItemIdx + ' / Item.Caption = ' + this.ViewProperties.ToolbarItems[a_iItemIdx].Caption);
    let sActionID:  string    = this.ViewProperties.ToolbarItems[a_iItemIdx].ID;
    switch (sActionID) {
      case 'tbSave':
        // Mehrfaches Ausführen von actionSave wird verhindert (TE)
        if(this.ViewProperties.ToolbarItems[a_iItemIdx].Enabled ) {
          this.actionSave(true, false);
        }
        this.ViewProperties.ToolbarItems[a_iItemIdx].Enabled = false;
       // this.actionRefresh();
      break;
      case 'tbRefresh':
        if ((this.ViewProperties.Instance as TvsViewDataEdit).ViewEditMode == TvsViewEditMode.vemInsert) {
          this.actionRefresh(TvsGetType.gtNewRecord);
        }
        else {
          this.actionRefresh(TvsGetType.gtNormal, (this.ViewProperties.Instance as vsViewData.TvsViewData).PKValue);
        }

      break;

      case 'tbActions':
        break;

      // Case "tbCustom" erstellt, der die Funtkion "command" auslöst, wenn ein ToolbarItem mit entsprechender ID gefunden wird.
      // Somit kann man custom ToolbarItems mit eigener Funktionalität hinzufügen.
      // Beispielhafte Anwendung: antrViewAntragEdit.component.ts --> toolbarItemExtension. Dort werden bestehende ToolbarItems überschrieben und ein neues hinzugefügt.
      case 'tbCustom':
        this.ViewProperties.ToolbarItems[a_iItemIdx].command();
        break;
      case 'tbOK':
        if(this.ViewProperties.ToolbarItems[a_iItemIdx].Enabled ) {
          //this.actionOK();
          this.ActionOK().then(() => {});
        }
        this.ViewProperties.ToolbarItems[a_iItemIdx].Enabled = false;
        break;
      case 'tbAbbruch':
          this.actionCancel();
        break;

      default:
        alert('ERROR: Toolbar - Unsupported Action-ID: ' + sActionID);
      break;
    } // switch
  } // btnToolbar_OnClick

  RetrieveNewData(a_sPKValue: string, a_bEdit, a_bClose: boolean){

  // Alternative-Rückantwort, wenn der PKValue bekannt ist aber kein Save aufgreufen wurde (objViewStammEdit ist der erste solche Fall)

    console.log('RetrieveNewData: ', a_sPKValue);

    this.DataFilter.ItemsClear();
    let item = this.DataFilter.ItemAdd('Filter PKValue');
    item.CriteriaAdd('PKValue', a_sPKValue);

    this.HttpServiceComponent.dataGet( this.ViewProperties.DataSourceID
                                       , this.ViewProperties.DOMain.KennFieldDefs
                                       ,[]
                                       ,this.DataFilter)
      .subscribe(
        data => {
          this.DataFilter.ItemsClear();
          (this.ViewProperties.Instance as TvsViewDataEdit).afterDataSave(data);
          (this.ViewProperties.Instance as TvsViewDataEdit).ResponseHandler(TvsHTTPMethod.hmPut, data, a_bClose);
          (this.ViewProperties.Instance as TvsViewDataEdit).IsModified = false;
          this.globalResources.ViewMain.msgShowToastSuccess('Datensatz wurde gespeichert');
          if (a_bEdit) {
             (this.ViewProperties.Instance as TvsViewDataEdit).actionEdit(a_sPKValue);
          }

        },
        error => {
          console.log('Error Get-Request: ', error);
          alert(  'Bei dem Get-Request ist ein Fehler aufgetreten:\n'
                + error.error.Error.ErrCode + '\n'
                + error.error.Error.ErrMsg + '\n\n'
                + error.message );
          this.DOMain.IsRefreshing = false;

              },
        () => {
          console.log('Get Request completed');
        }

     ); // dataGet
     (this.ViewProperties.Instance).ViewProperties.detailDataId = a_sPKValue;
     (this.ViewProperties.Instance as TvsViewDataEdit).detailDataSynchronize();


  }





} // TvsViewDataEdit


